import React from "react";
import Select from "react-select";
import { useState } from "react";
const SingleSelect = ({
  selectOptions,
  placeholder,
  handleSelect,
  defaultValue,
  isDisabled,
}) => {
  const [selectedOption, setSelectedOptions] = useState([{ name: "" }]);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    handleSelect(selectedOptions);
  };

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#347ebf87!important",
          border: "1px solid #347ebf87!important"
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          padding: "2px",
          fontSize: 12,
          height: "42px",
          // backgroundColor: "rgb(247,248,250)",
          border: "1px solid #347ebf87!important",
          borderRadius: "0px",
        }),
      }}
      isClearable
      name="colors"
      options={selectOptions}
      className="basic-single-select"
      classNamePrefix="test"
      placeholder={placeholder}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      onChange={handleChange}
    />
  );
};
export default SingleSelect;
