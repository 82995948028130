import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import Signup from "./components/Signup";
import DashboardRunner from "./components/AdminDashboard/DashboardRunner";
import ProtectedRoute from "./Auth/ProtectedRoute";
import { AuthProvider } from "./Auth/AuthContext";
import NotFoundPage from "./pages/NotFoundPage";
import ValuationRequest from "./pages/ValuationRequest";
import Navbar from "./components/NavbarHOC/NavbarHOC";
import "./App.css";
import Home from "./pages/Home/Home";
import { Sidebar } from "./components/Core/Sidebar";
// import EditRequest from "./pages/ValuationRequest";
import EditRequest from "./pages/ValuationRequest/editRequest";
import Profile from "./pages/Profile/index";
import ChangePassword from "./pages/Profile/changePassword";
import RaiseATicket from "./pages/NeedHelp/RaiseATicket";
import RaiseATicketList from "./pages/NeedHelp/RaiseATicketList"

function App() {
  return (
    <AuthProvider>
      <Router>
        <Sidebar />
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DashboardRunner />} />
            <Route path="/valuationRequest" element={<ValuationRequest />} />
            <Route path="/editRequest/:requestId" element={<EditRequest />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/changePassword" element={<ChangePassword />} />
            <Route path="/raiseATicket" element={<RaiseATicket />} />
            <Route path="/raiseATicket/:encid" element={<RaiseATicket />} />
            <Route path="/ticketList" element={<RaiseATicketList />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
// changes 26
