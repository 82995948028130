import React, { useEffect, useState, useContext } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import MultipleSelect from "../../UI/MultipleSelectionDropdown";
import SingleSelect from "../../UI/SingleSelectDropdown";
import { getValuationRequestData } from "../../services/masterDataService";
import { getCountryData } from "../../services/getCountryCode";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  EMIRATE,
  TYPE_OF_PROPERTY,
  PURPOSE_OF_VALUATION,
  INSPECTION_TYPE,
  TYPE_OF_NON_INSPECTION_PROPERTY,
  INSPECTION_TYPE_PROPERTY,
} from "../../constants";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import FileUpload from "../../components/FileUpload";
import "./request.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../services/httpRequests";
import Profile from "../../components/Profile/index";
import { AuthContext } from "../../Auth/AuthContext";
import CustomLoader from "../../components/CustomLoader";

const Request = (param) => {
  const initialValues = {
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    phonenumber: "",
    selectedPropertyOption: [],
    selectedEmirateOption: [],
    selectedValuationOption: "",
    selectedInspectionOption: "",
    propertySize: "",
    plotArea: "",
    propertyAddress: ""
  };
  const [formValue, setFormValue] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [emirateOptions, setEmirateOption] = useState([]);
  const [inspectionTypeOptions, setInspectionTypeOptions] = useState([]);
  const [typeofPropertyOptions, setTypeOfPropertyOption] = useState([]);
  const [typeOfNonInspectionProperty, setTypeOfNonInspectionProperty] =
    useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [PurposeofValuationOptions, setPurposeOfValuationOption] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  let [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const { loading, setLoading } = useContext(AuthContext);

  const categories = [
    EMIRATE,
    TYPE_OF_PROPERTY,
    PURPOSE_OF_VALUATION,
    INSPECTION_TYPE,
    TYPE_OF_NON_INSPECTION_PROPERTY,
    INSPECTION_TYPE_PROPERTY,
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getValuationRequestData(categories);

        console.log("getmasterDataResponse:", response);
        // const countryResponse = await getCountryData();

        const data = response?.data;
        // const countryData = countryResponse?.data?.recordsets[0];

        const [
          emirateData,
          typeOfPropertyData,
          purposeOfValuationData,
          InspectionType,
          typeOfNonInspectionProperty,
          countryCodesData,
        ] = await Promise.all([
          data
            .filter(({ category }) => category === EMIRATE)
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),
          data
            .filter(({ category }) => category === TYPE_OF_PROPERTY)
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),
          data
            .filter(({ category }) => category === PURPOSE_OF_VALUATION)
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),
          data
            .filter(({ category }) => category === INSPECTION_TYPE)
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),
          data
            .filter(
              ({ category }) => category === TYPE_OF_NON_INSPECTION_PROPERTY
            )
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),

          //   countryData.map((item) => ({
          //     id: item.CountryId,
          //     value: item.CountryName,
          //     label: item.CountryName,
          //   })),
        ]);

        setInspectionTypeOptions(InspectionType);
        setEmirateOption(emirateData);
        setTypeOfPropertyOption(typeOfPropertyData);
        setTypeOfNonInspectionProperty(typeOfNonInspectionProperty);
        setPurposeOfValuationOption(purposeOfValuationData);
        setCountryCodes(countryCodesData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  console.log("InspectionType:", inspectionTypeOptions);
  console.log("typeofPropertyOptions:", typeofPropertyOptions);
  console.log("typeOfNonInspectionProperty:", typeOfNonInspectionProperty);

  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handlePhoneInput = (e) => {
    console.log("phoneInput:", e);
    setFormValue({ ...formValue, phonenumber: e });
  };

  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFilesSelected = (files, key) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [key]: files[0],
    }));
  };

  const [propertySelect, setPropertySelect] = useState([]);
  console.log(selectedFiles, "selectedFilesForupload");

  const handlePropertySelect = (selectedOptions) => {
    setPropertySelect(selectedOptions);
    const values = selectedOptions.map((options) => {
      return options.id;
    });
    setFormValue({ ...formValue, selectedPropertyOption: values });
  };

  const handleEmirateSelect = (selectedOptions) => {
    const values = selectedOptions.map((options) => {
      return options.id;
    });
    setFormValue({ ...formValue, selectedEmirateOption: values });
  };

  const handleCountryCodeSelect = (selectedOptions) => {
    setFormValue({
      ...formValue,
      countryId: selectedOptions?.id || "",
    });

    console.log("form Value: ", formValue);
  };

  const handleValuationSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions);
    setFormValue({
      ...formValue,
      selectedValuationOption: parseInt(selectedOptions?.id || ""),
    });
  };

  const handleInspectionType = (selectedOptions) => {
    console.log("InspectionSelection:", selectedOptions);

    setFormValue({
      ...formValue,
      selectedInspectionOption: selectedOptions
        ? parseInt(selectedOptions?.id)
        : "",
      selectedPropertyOption: "",
    });

    if (selectedOptions?.value === INSPECTION_TYPE_PROPERTY) {
      setPropertyTypeList(typeofPropertyOptions);
    } else {
      setPropertyTypeList(typeOfNonInspectionProperty);
    }

    setPropertySelect(null);
  };

  const formData = new FormData();

  async function submitForm(e) {
    debugger;
    e.preventDefault();
    if (formValue.propertySize == "") {
      formValue.propertySize = "0"
    }
    setFormValue(formValue);
    setFormErrors(validate(formValue));
    setIsSubmit(true);
    formData.append("companyName", formValue.companyName);
    // formData.append("individualName", formValue.individualName);
    formData.append("firstName", formValue.firstName);
    formData.append("lastName", formValue.lastName);
    formData.append("email", formValue.email);
    formData.append("phonenumber", formValue.phonenumber);
    formData.append("propertySize", formValue.propertySize);
    formData.append("plotArea", formValue.plotArea);
    formData.append("propertyAddress", formValue.propertyAddress);
    formData.append(
      "selectedPropertyOption",
      JSON.stringify(formValue.selectedPropertyOption)
    );
    formData.append(
      "selectedEmirateOption",
      JSON.stringify(formValue.selectedEmirateOption)
    );
    formData.append(
      "selectedValuationOption",
      formValue.selectedValuationOption
    );
    formData.append(
      "selectedInspectionOption",
      formValue.selectedInspectionOption
    );
    formData.append("countryId", "1");
    // formData.append(
    //   "userId",
    //   JSON.parse(localStorage.getItem("USER_INFO")).userId
    // );

    for (const key in selectedFiles) {
      if (selectedFiles.hasOwnProperty(key)) {
        formData.append("documents", selectedFiles[key]);
      }
    }
    // debugger;
    if (Object.keys(errors).length === 0) {
      // debugger;
      setIsDisabled(true);
      setLoading(true);
      try {
        const BASE_URL = getBaseUrl();
        const response = await axios.post(
          `${BASE_URL}/request/add-valuation`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );
        setIsDisabled(false);
        setLoading(false);

        console.log("response Status:", response);

        if (response?.data?.success == 1) {
          // debugger;
          setFormValue(initialValues)
          toast.success(<h6>{`${response?.data?.result}`}</h6>, {
            toastId: "form-success",
            position: "top-center",
            autoClose: 2500,
            onClose: () => {
              setIsSubmit(false);
            },
          });
          navigate('/dashboard')
        } else {
          toast.error(response?.data?.message || "Some error occurred.", {
            toastId: "form-success",
            onClose: () => {
              setIsDisabled(false);
              setIsSubmit(false);
            },
          });
        }
      } catch (error) {
        console.error(error);

        toast.error("Some error occurred.", {
          toastId: "form-error",
          onClose: () => {
            setIsDisabled(false);
            setIsSubmit(false);
          },
        });
      }
    } else {
      toast.error("Please fix the form errors.", {
        toastId: "form-error",
        onClose: () => {
          setIsDisabled(false);
          setIsSubmit(false);
        },
      });
    }
  }
  const errors = {};

  const validate = (values) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex = /^\+\d{1,}[0-9]{1,}$/;
    // const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\./0-9]*$/g;
    const nameRegex = /^[A-Za-z'\s]+$/;
    const propertySizeRegex = /^[0-9]+$/;

    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

    if (values?.companyName?.length === 0) {
      errors.companyName = "Required";
    } else if (!alphanumericRegex.test(values.companyName)) {
      errors.companyName = "Should contain only alphabets and numbers";
    }

    if (values?.firstName?.length === 0) {
      errors.firstName = "Required";
    } else if (!nameRegex.test(values.firstName)) {
      errors.firstName = "Should contain only alphabet";
    }
    if (values?.lastName?.length === 0) {
      errors.lastName = "Required";
    } else if (!nameRegex.test(values.lastName)) {
      errors.lastName = "Should contain only alphabet";
    }

    //email
    if (values?.email?.length === 0) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Not a valid email";
    }

    //Phone & Telephone Numbers
    if (values?.phonenumber?.length === 0) {
      errors.phonenumber = "Required";
    } else if (values.phonenumber?.length > 15) {
      errors.phonenumber = "Enter valid number";
    }
    //valuationOption
    if (values?.selectedValuationOption?.length === 0) {
      errors.selectedValuationOption = "Required";
    }
    if (values?.selectedEmirateOption?.length === 0) {
      errors.selectedEmirateOption = "Required";
    }
    if (values?.selectedInspectionOption?.length === 0) {
      errors.selectedInspectionOption = "Required";
    }
    if (values?.selectedPropertyOption?.length === 0) {
      errors.selectedPropertyOption = "Required";
    }

    // propertySize

    if (values?.propertySize < 0) {
      errors.propertySize = "Numbers must be greater than 0";
    }
    // if (values.countryId === "") {
    //   errors.countryId = "Please Select Country";
    // }

    //CountryCode
    // if (!values?.countryId == null) {
    //   errors.countryId = "Please Select Country";
    // }

    return errors;
  };
  console.log("formValue:", formValue);

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors)?.length === 0 && isSubmit) {
      console.log(" form Values is : " + formValue);
    }
  }, [formErrors]);

  return (
    <div className="__body">
      <Profile />
      <header className="Dashboard_header">
        <div className="topHeader">
          {/* <div className="navigation d-flex">
            <ChecklistIcon /> <span className="separator">/</span>
            <div className="text">Valuation</div>
          </div> */}
          <div className="topHeader">Valuation Request Form</div>
        </div>

      </header>
      <div className="valuation_request_Container">
        <div className="request_Form_Container">
          <span className="title">COMPANY INFO</span>
          <div className="basic">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Company Name <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="companyName"
                    placeholder="Enter Company Name"
                    value={formValue.companyName}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.companyName}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    placeholder="Enter First Name"
                    value={formValue.firstName}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.firstName}
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={formValue.lastName}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.lastName}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    value={formValue.email}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.email}
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Mobile Number <span className="required">*</span>
                  </label>
                  <PhoneInput
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    onChange={handlePhoneInput}
                  />
                  <label className="required required-error">
                    {formErrors.phonenumber}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Emirates <span className="required">*</span>
                  </label>
                  <MultipleSelect
                    selectOptions={emirateOptions}
                    placeholder="Select Emirates"
                    handleSelect={handleEmirateSelect}
                  />
                  <label className="required required-error">
                    {formErrors?.selectedEmirateOption}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Type of property <span className="required">*</span>
                  </label>
                  <SingleSelect
                    selectOptions={inspectionTypeOptions}
                    placeholder="Select Type of property"                    
                    handleSelect={handleInspectionType}
                  />
                  <label className="required required-error">
                    {formErrors.selectedInspectionOption}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Property List <span className="required">*</span>
                  </label>

                  <div
                    data-tooltip-id="ToolTippropertySelectId"
                    data-tooltip-content="Please select type of property first!"
                    data-tooltip-place="top"
                    data-tooltip-variant="info"
                  >
                    <MultipleSelect
                      selectOptions={propertyTypeList}
                      handleSelect={handlePropertySelect}
                      selectedOption={propertySelect}
                      isDisabled={
                        formValue?.selectedInspectionOption ? false : true
                      }
                      placeholder="Select Property"
                    />
                  </div>

                  <Tooltip
                    id="ToolTippropertySelectId"
                    hidden={formValue?.selectedInspectionOption ? true : false}
                  />

                  <label className="required required-error">
                    {formErrors.selectedPropertyOption}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Purpose of Valuation <span className="required">*</span>
                  </label>
                  <SingleSelect
                    selectOptions={PurposeofValuationOptions}
                    placeholder={
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                        }}
                        title="Purpose of Valuation 3"
                      >
                        Select Purpose of Valuation
                      </span>
                    }
                    handleSelect={handleValuationSelect}
                  />
                  <label className="required required-error">
                    {formErrors.selectedValuationOption}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    BUA  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    name="propertySize"
                    placeholder="Enter BUA"
                    value={formValue.propertySize}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.propertySize}
                  </label>

                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Plot Area
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="plotArea"
                    placeholder="Enter Plot Area"
                    value={formValue.plotArea}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Property Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="propertyAddress"
                    placeholder="Enter Property Address"
                    value={formValue.propertyAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="request_Form_Container mt-5">
          <span className="title">SUPPORTING DOCUMENTS</span>
          <div className="document">
            <div className="file_container">
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#cccccc29" }}>
                    <TableCell style={{ textTransform: "uppercase" }}>
                      Document Name
                    </TableCell>
                    <TableCell style={{ textTransform: "uppercase" }}>
                      Upload
                    </TableCell>
                    <TableCell style={{ textTransform: "uppercase" }}>
                      Status
                    </TableCell>
                    <TableCell style={{ textTransform: "uppercase" }}>
                      Comments
                    </TableCell>
                    {/* <TableCell style={{ textTransform: "uppercase" }}>
                      History
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1. Title Deed</TableCell>
                    <TableCell>
                      <FileUpload
                        onFilesSelected={(files) =>
                          handleFilesSelected(files, "documents")
                        }
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ position: "relative" }}>
                      <textarea
                        disabled
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          padding: "8px",
                          boxSizing: "border-box",
                          backgroundColor: "#f5f5f5",
                        }}
                      // defaultValue="Default comment"
                      ></textarea>
                    </TableCell>
                    {/* <TableCell>
                      <HistoryIcon />
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>2. Affection Plan / Site Plan</TableCell>
                    <TableCell>
                      <FileUpload
                        onFilesSelected={(files) =>
                          handleFilesSelected(files, "sitePlan")
                        }
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ position: "relative" }}>
                      <textarea
                        disabled
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          padding: "8px",
                          boxSizing: "border-box",
                          backgroundColor: "#f5f5f5",
                        }}
                      // defaultValue="Default comment"
                      ></textarea>
                    </TableCell>
                    {/* <TableCell>
                      <HistoryIcon />
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      3.Floor Plans / Architectural Drawings
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        onFilesSelected={(files) =>
                          handleFilesSelected(files, "floorPlans")
                        }
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ position: "relative" }}>
                      <textarea
                        disabled
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          padding: "8px",
                          boxSizing: "border-box",
                          backgroundColor: "#f5f5f5",
                        }}
                      // defaultValue="Default comment"
                      ></textarea>
                    </TableCell>
                    {/* <TableCell>
                      <HistoryIcon />
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell>4. Related documents</TableCell>
                    <TableCell>
                      <FileUpload
                        onFilesSelected={(files) =>
                          handleFilesSelected(files, "relatedDocs")
                        }
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ position: "relative" }}>
                      <textarea
                        disabled
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          padding: "8px",
                          boxSizing: "border-box",
                          backgroundColor: "#f5f5f5",
                        }}
                      // defaultValue="Default comment"
                      ></textarea>
                    </TableCell>
                    {/* <TableCell>
                      <HistoryIcon />
                    </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div className="request_Form_Container mt-3"  style={{ display: "flex", alignItems: "end" }}>
          <div className="justify-content-between d-flex btn-group">
            <div style={{"marginRight":"3px"}}>
              <Button variant="contained" disabled={isDisabled} className="btn-cancel" color="error">
                <Link to="/dashboard">Cancel</Link>
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                className="btn-submit"
                color="success"
                onClick={submitForm}
                disabled={isDisabled}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
