import React from "react";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getUser, updateUser } from "../../services/addUserService"
import NavBarProfile from "../../components/Profile/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button
} from "@mui/material";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

const Profile = (props) => {
  const navigate = useNavigate();

  const initProfile = {
    firstname: "",
    lastname: "",
    emailAddress: "",
    mobile: "",
    userAddress: ""
  }

  const [userData, setUserData] = useState(initProfile);
  const [profile, setProfile] = useState(initProfile);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const getUserData = () => {
    const token = localStorage.getItem("accessToken");
    var decoded = jwt_decode(token);
    console.log(decoded?.userId);
    getUser(decoded?.userId)
      .then(response => {
        const user =  { ...response?.result.data[0] };
        user.userName = user.firstname + ' ' + user?.lastname 
        localStorage.setItem("USER_INFO", JSON.stringify(user));
        setUserData({ ...response?.result.data[0] });
        setProfile({ ...profile, ...response?.result.data[0] });
      }).catch(err => {
        console.log(err);
        toast.error("An error occurred. Please try again later.", {
          toastId: "form-success",
          onClose: () => {
            setIsSubmit(false);
          },
        });
        if (err?.error == 'Invalid Token') {
          localStorage.removeItem("accessToken");
          navigate("/login");
        }


      })
  }

  useEffect(() => {
    getUserData()
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    console.log(profile);
  }

  const handlePhoneInput = (e) => {
    if (!e || e?.length < 16) {
      setProfile({ ...profile, mobile: e });
      setFormErrors({...formErrors, mobile: null })
    }
    if (e?.length > 16) {
     setFormErrors({...formErrors, mobile: "Phone number must be less than 15 characters." })
    }
  };


  const submitForm = (e) => {
    const errors = validate(profile);
    setFormErrors(errors);

    console.log(Object.keys(errors).length);

    if (Object.keys(errors).length === 0) {

      console.log(profile);
      const token = localStorage.getItem("accessToken");
      var decoded = jwt_decode(token);
      const userId = decoded?.userId
      const payload = { ...profile };
      delete payload.userId;

      updateUser(payload, userId).then(response => {

        if (response.success) {
          getUserData()

          toast.success("Successfully updated the user", {
            toastId: "form-success",
            onClose: () => {
              setIsSubmit(false);
            },
          });

        } else {
          toast.error("An error occurred. Please try again later.", {
            toastId: "form-success",
            onClose: () => {
              setIsSubmit(false);
            },
          });
        }
      }).catch(err => {
        console.log(err);
        toast.error("An error occurred. Please try again later.", {
          toastId: "form-success",
          onClose: () => {
            setIsSubmit(false);
          },
        });
      });


    } else {

    }

  }
  const isDisabled = formErrors.mobile || profile.firstname == userData.firstname &&
    profile.lastname == userData.lastname &&
    profile.emailAddress == userData.emailAddress &&
    profile.mobile == userData.mobile &&
    profile.userAddress == userData.userAddress;

  const validate = (values) => {
    console.log(values);

    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const nameRegex = /^[A-Za-z']+$/;
    const addressRegex = /^[a-zA-Z0-9\s\.,#\-]*$/;
    const phoneRegex = /^\+\d{1,}[0-9]{1,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    //nameValidations
    if (values.firstname.length === 0) {
      errors.firstname = "Required";
    } else if (!nameRegex.test(values.firstname)) {
      errors.firstname = "Should contain only alphabet";
    }

    if (values.lastname.length === 0) {
      errors.lastname = "Required";
    } else if (!nameRegex.test(values.lastname)) {
      errors.lastname = "Should contain only alphabet";
    }

    //email
    if (values.emailAddress.length === 0) {
      errors.emailAddress = "Required";
    } else if (!emailRegex.test(values.emailAddress)) {
      errors.emailAddress = "Not a valid email";
    }

    //address
    if (values.userAddress.length === 0) {
      errors.userAddress = "Required";
    } else if (!addressRegex.test(values.userAddress)) {
      errors.userAddress = "Should contain valid fields";
    }

    //Phone & Telephone Numbers

    if (values.mobile) {

      if (values.mobile.length === 0) {
        errors.mobile = "Required";
      } else if (
        values.mobile.length < 12 ||
        values.mobile.length > 16
      ) {
        errors.mobile = "Enter valid number";
      } else if (!phoneRegex.test(values.mobile)) {
        errors.mobile = "Should contain only numbers";
      }
    }
    else { errors.mobile = "Required"; }

    return errors;
  };

  return (
    <div className="__body">
      <NavBarProfile />

      <header className="Dashboard_header">
        <div className="topHeader">
          <div className="topHeader">User Profile</div>
        </div>

      </header>
      <div className="valuation_request_Container">
        <div className="request_Form_Container">
          <span className="title">User Info</span>
          <div className="basic">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    maxlength="50"
                    name="firstname"
                    placeholder="First Name"
                    value={profile.firstname}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.firstname}
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    maxlength="50"
                    className="form-control"
                    name="lastname"
                    placeholder="Last Name"
                    value={profile.lastname}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.lastname}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    disabled
                    placeholder="Email"
                    value={profile.emailAddress}
                    onChange={handleChange}
                    readOnly
                  />
                  <label className="required required-error">
                    {formErrors.emailAddress}
                  </label>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Phone Number <span className="required">*</span>
                  </label>
                  <PhoneInput
                    className="form-control"
                    placeholder="Phone"
                    value={profile.mobile}
                    onChange={handlePhoneInput}
                  />
                  <label className="required required-error">
                    {formErrors.mobile}
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="form-group">
                  <label>
                    Address <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="address"
                    maxlength="100"
                    name="userAddress"
                    placeholder="User Address"
                    value={profile.userAddress}
                    onChange={handleChange}
                  />
                  <label className="required required-error">
                    {formErrors.userAddress}
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="request_Form_Container mt-3" style={{ display: "flex", alignItems: "end" }}>
          <div className="justify-content-between d-flex btn-group">
            <div className="row">
              <div className="col-md-6">
                <Button variant="contained" className="btn-cancel" color="error">
                  <Link to="/dashboard">Cancel</Link>
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="contained"
                  disabled={isDisabled}
                  className="btn-submit"
                  color="success"
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Profile;