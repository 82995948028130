import React from "react";
import { useState,useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {getUser,changePassword} from "../../services/addUserService"
import Profile from "../../components/Profile/index";
import { Link, useNavigate } from "react-router-dom";
import {
    Button   
  } from "@mui/material";
  import jwt_decode from "jwt-decode";
  import { toast } from "react-toastify";

const ChangePassword = (props) =>{

    const initProfile = {
       currentPassword:'',
       newPassword : '',
       confirmPassword : '',
       userId : ''
    }

    const [profile,setProfile] = useState(initProfile);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const navigate = useNavigate();
 
    const handleChange = (e) => {    
       const {name,value} = e.target; 
       setProfile({...profile,[name] : value});       
    }    

    const submitForm = (e) => {  
        console.log(profile);  

        const token = localStorage.getItem("accessToken");
        var decoded = jwt_decode(token);        
        const errors = validate(profile);

        console.log(decoded?.userId);
             

        setFormErrors(errors);

        console.log(Object.keys(errors).length);

        if(Object.keys(errors).length ===  0){
            
            console.log(profile);
            const profileClone = {...profile};
            profileClone.userId = decoded?.userId;
            changePassword(profileClone).then(response => {
              if(response.success){              

                toast.success("Successfully changed the password", {
                    toastId: "form-success",                    
                    onClose: () => {
                      setIsSubmit(true); 
                      localStorage.removeItem("accessToken");
                      navigate("/login");                                                     
                    },                    
                  });

                }else{
                  toast.error(response?.result?.err, {
                    toastId: "form-success",
                    onClose: () => {
                    setIsSubmit(false);
                    },
                  });
                }
            }).catch(err => {
                console.log(err);
                toast.error("An error occurred. Please try again later.", {
                    toastId: "form-success",
                    onClose: () => {
                    setIsSubmit(false);
                    },
                  });
            });


        }else{

        }

    }

    const validate = (values) => {
        console.log(values);
    
        const errors = {};       
        const passwordRegex =
        /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;



        if (values.currentPassword.length === 0) {
            errors.currentPassword = "Required";
          }

          if (values.newPassword.length === 0) {
            errors.newPassword = "Required";
          } else if (values.newPassword.length < 7) {
            errors.newPassword = "Password should be more than 6 digit";
          } else if (!passwordRegex.test(values.newPassword)) {
            errors.newPassword =
              "Should contain 1 digit, 1 uppercase, 1 lowercase and 1 special character !@#$%^&*";
          }
      
          //
      
          if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
          } else if (values.confirmPassword.length < 6) {
            errors.confirmPassword = "Password should be more than 6 digit";
          } else if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Did not match with Password";
          }
       
            return errors;
      };
    const isDisabled = !profile.currentPassword || !profile.newPassword || !profile.confirmPassword


    return (
        <div className="__body">
          <Profile />
        <header className="Dashboard_header">
          <div className="topHeader">     
            <div className="topHeader">Change Password</div>  
          </div>       
         
        </header>
        <div className="valuation_request_Container">
          <div className="request_Form_Container">
            <span className="title">User Info</span>
            <div className="basic">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>
                      Current Password <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="currentPassword"
                      placeholder="Enter Current Password"
                      value={profile.currentPassword}
                      onChange={handleChange}
                    />
                    <label className="required required-error">
                      {formErrors.currentPassword}
                    </label>
                  </div>
                </div>
                </div> 
                <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>
                      New Password <span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="newPassword"
                      placeholder="Enter New Password"
                      value={profile.newPassword}
                      onChange={handleChange}
                    />
                    <label className="required required-error">
                      {formErrors.newPassword}
                    </label>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>
                      Confirm Password <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      value={profile.confirmPassword}
                      onChange={handleChange}                      
                    />
                    <label className="required required-error">
                      {formErrors.confirmPassword}
                    </label>
                  </div>
                </div>
                </div>
              
           
            </div>
          </div>        
          <div className="request_Form_Container mt-3" style={{display:"flex" , alignItems:"end"}}>
            <div className="justify-content-between d-flex btn-group">
            <div className="row">
              <div className="col-md-6">
                <Button variant="contained" className="btn-cancel" color="error">
                  <Link to="/dashboard">Cancel</Link>
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  variant="contained"
                  className="btn-submit"
                  color="success"
                  disabled={isDisabled}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

}

export default ChangePassword;