import PropTypes from "prop-types";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import Card from "@mui/material/Card";

import configs from "./configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function VerticalBarChart({ icon, title, description, height, chart }) {
  const chartDatasets = [
    {
      ...chart.datasets,
      weight: 5,
      borderWidth: 0,
      borderRadius: 4,
      backgroundColor: "#fff",
      fill: false,
      maxBarThickness: 35,
    },
  ];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = <Bar data={data} options={options} redraw />;

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

VerticalBarChart.defaultProps = {
  icon: { color: "primary", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

VerticalBarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default VerticalBarChart;
