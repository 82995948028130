import React, { useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavBarProfile from "../../components/Profile/index";
import { BASE_URL, HttpGet } from '../../services/httpRequests';
import MaterialTable, { MTableToolbar } from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TaskAlt from "@mui/icons-material/TaskAlt";
import EmailIcon from '@mui/icons-material/Email';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AdbIcon from '@mui/icons-material/Adb';
import ApartmentIcon from "@mui/icons-material/Apartment";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { DateRange } from "@material-ui/icons";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import './RaiseATicket.css';
import moment from "moment";
import { textTransform } from "@mui/system";
import { Tooltip } from "@mui/material";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const RaiseATicketList = () => {
    const [modelData, setModelData] = useState({
        projectData: []
    });

    const getTicketList = async () => {
        debugger;
        try {
            const userId = JSON.parse(localStorage.getItem("USER_INFO")).userId;
            const base = BASE_URL;
            const ticketData = await HttpGet(BASE_URL + `/request/getTicketListData/${userId}`);

            if (ticketData?.result[0]?.length === 0) {
                console.error('no data');
            }
            else {
                setModelData({ ...modelData, projectData: ticketData.result[0] })
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getTicketList()
    }, [])

    const formatDate = (date) => {
        try {
            if (date) {
                return moment(date).format("DD-MMM-YYYY");
            } else {
                return "No date";
            }
        } catch (error) {
            return "No date";
        }
    }
 const navigate = useNavigate()
    const btnAction = (e) => {
        const enc='yttrf56ed'
        let path = `/RaiseATicket/${enc}`;
        navigate(path,{
            state:{
                ticketData:e
            }
        })
    }

    return (
        <div className="__body">
            <NavBarProfile />
            <header className="Dashboard_header">
                <div className="topHeader">
                    <div className="topHeader">Raise A Ticket</div>
                </div>

            </header>
            <div className="valuation_request_Container">
                <div className="tickeList">
                    <MaterialTable
                        title="Ticket List"
                        icons={tableIcons}
                        columns={[
                            {
                                title: 'Request No.',
                                field: 'id',
                                render: (rowData) => (
                                    <div className="requestNo"  onClick={(event)=>{btnAction(rowData)}} >
                                        <BookmarkAddedIcon />
                                        {rowData?.id}
                                    </div>
                                ),
                                cellStyle: {
                                    width: '5%',
                                },
                            },
                            {
                                title: 'Full Name',
                                field: 'fullName',

                                render: (rowData) => (
                                    <div className="fullName">
                                        <TaskAlt />
                                        {rowData?.fullName}
                                    </div>
                                ),
                                cellStyle: {
                                    width: '20%',
                                },
                            },
                            {
                                title: 'Email Address',
                                field: 'email',
                                render: (rowData) => (
                                    <div className="email">
                                        <EmailIcon />
                                        {rowData?.email}
                                    </div>
                                ),
                                cellStyle: {
                                    width: '16%',

                                },
                            },
                            {
                                title: 'Mobile Number',
                                field: 'mobileNumber',
                                render: (rowData) => (
                                    <div className="email">
                                        <AddIcCallIcon />
                                        {rowData?.mobileNumber}
                                    </div>
                                ),
                            },
                            {
                                title: 'Problem Type',
                                field: 'problemType',
                                render: (rowData) => (
                                    <div className="email">
                                        <ApartmentIcon />
                                        {rowData?.problemType}
                                    </div>
                                ),
                                cellStyle: {
                                    width: '15%',

                                },
                            },
                            {
                                title: 'Problem Title',
                                field: 'problemTitle',
                                render: (rowData) => (
                                    <div className="problemTitle">
                                        <AdbIcon />
                                        {rowData?.problemTitle}
                                    </div>
                                ),
                            },
                            {
                                title: 'Problem Summary',
                                field: 'problemSummary',
                                render: (rowData) => (
                                    <Tooltip title={rowData?.problemSummary} placement="bottom">
                                    <div className="problemTitle">
                                        <DisplaySettingsIcon />
                                        {rowData?.problemSummary.substring(0,30)}
                                    </div>
                                    </Tooltip>
                                ),
                            },
                            {
                                title: 'Resolution Date',
                                field: 'resolutionDate',
                                render: (rowData) => (
                                    <div className="problemTitle">
                                        <DateRange />
                                        {formatDate(rowData?.resolutionDate)}
                                    </div>
                                ),
                            },
                            {
                                title: 'Resolution Summary',
                                field: 'resolutionSummary',
                                render: (rowData) => (
                                    <div className="problemTitle">
                                    <Tooltip title={rowData?.resolutionSummary} placement="top">
                                    <DisplaySettingsIcon />
                                     {rowData?.resolutionSummary?.substring(0,30)}
                                    </Tooltip>
                                       
                                    </div>
                                ),
                            },
                            {
                                title: 'Resolver By',
                                field: 'resolverEmail',
                                render: (rowData) => (
                                    <div className="resolverEmail">
                                        <EmailIcon />
                                        {rowData?.resolverEmail}
                                    </div>
                                ),
                            },
                            {
                                title: 'Created On',
                                field: 'createdOn',
                                render: (rowData) => (
                                    <div className="problemTitle">
                                        <DateRange />
                                        {formatDate(rowData?.createdOn)}
                                    </div>
                                ),
                                cellStyle: {
                                    width: '10%',

                                },
                            },
                            {
                                title: 'Status',
                                field: 'statusName',
                                render: (rowData) => (
                                    <div className="domain-part d-flex">
                                        <input className={rowData.statusCode =="SRNRSD"? "btnStatus":"btnStatusCompltd"}  onClick={(event)=>{btnAction(rowData)}} type='button' value={rowData?.statusName} />
                                    </div>
                                ),
                            },
                        ]}
                        data={modelData?.projectData}
                        options={{
                            headerStyle: {
                                background: "rgb(249, 249, 249)",
                                color: "#212529",
                                fontSize: "14px",
                                fontWeight: "bold",
                                textTransform: "uppercase"
                            },
                            rowStyle: {
                                // fontWeight: "500 !important;",
                                // color: "#212529",
                                // fontSize: "14px",
                                // fontWeight: "bold",
                                // marginTop: "0px",                           
                            },
                            searchFieldStyle: {
                                background: "rgb(249, 249, 249)",
                            },
                            search: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30 ,50, 75, 100 ],
        toolbar: true,
        paging: true
                            //filtering: true
                            //grouping: true
                        }}
                    />
                </div>
            </div>

        </div>
    );
}

export default RaiseATicketList;


