import React from "react";
import NavBar from "../../components/AdminDashboard/Navbar";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const { pathname } = location;
  const hideNavbar = pathname === "/login" || pathname === "/signup" || pathname === "/";

  return (
    <>
      {!hideNavbar && <NavBar />}
    </>
  );
}

export default Navbar;