import React, { useRef, useState, useEffect } from "react";
import RequestDropdown from "../../UI/Request_dropdown";
import DateRange from "../../UI/DateRange";
import "bootstrap/dist/css/bootstrap.min.css";
import MyTable from "../../UI/Tables";
import "./index.css";
import { Home, Receipt } from "@mui/icons-material";
import PendingIcon from "@mui/icons-material/Pending";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import NotesIcon from "@mui/icons-material/Notes";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Profile from "../Profile/index";
import { getDashboadRequest } from "../../services/dashboardService";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import VerticalBarChart from "../Core/Charts/BarChart";
import { useNavigate } from "react-router-dom";

const AdminDashboard = ({ data }) => {
  document.title =  'Hamptons - Dashboard'

  const initRequest = [];
  const [dashboardRequest, setDashboardRequest] = useState(initRequest);
  const [dashboardFilterRequest, setdashboardFilterRequest] = useState(initRequest);
  const [graphAllRequestData, setgraphAllRequestData] = useState([])
  const [grapAllData, setgrapAllData] = useState([])
  const [grapPendingData, setgrapPendingData] = useState([])
  const [grapApprovedData, setgrapApprovedData] = useState([])
  const [grapRejectedData, setgrapRejectedData] = useState([])
  const navigate = useNavigate();


  const handleCardSummary = (statusType, statusId) => {
    var fileterData = dashboardRequest;
    if (statusType !== 'all') {
      fileterData = dashboardRequest.filter((i) => i.StatusId == statusId)
      console.log("fileterData" + JSON.stringify(fileterData))      
    }
    setdashboardFilterRequest(fileterData)
  }

  const handleChange = async (value) => {
    console.log("host component " + value);
    await getData(value);
  };

  const CardSummary = ({ requestType, className, num, icon, type, StatusId }) => {
    return (
      <div className="card" onClick={() => handleCardSummary(type, StatusId)}>
        <div className="justify-content-between d-flex">
          <div className={`indi ${className}`}>{icon}</div>

          <div className="detail">
            <span>{requestType}</span>
            <h4>{num}</h4>
          </div>
        </div>
        <div className="break"></div>
        <div className="summary"></div>
        {/* <div className="summary">{num}</div> */}
        {/* <Link></Link> */}
      </div>
    );
  };

  let totalCount = useRef("0");
  let totalApprovedCount = useRef("0");
  let totalPendingCount = useRef("0");
  let totalNotApprovedCount = useRef("0");

  useEffect(() => {   
    //setgraphAllRequestData([50, 20, 10, 22, 50, 10, 40,50, 20, 10, 22, 50])    
    async function getDashboardRequests() {
      await getData();
    }
    getDashboardRequests();
  }, []);

  const getData = async (statusId) => {
    console.log("get data invoked with status id = " + statusId);
    try {
      const token = localStorage.getItem("accessToken");
      var decoded = jwt_decode(token);
      const userId = decoded?.userId;
      const response = await getDashboadRequest(userId, statusId);
      console.log(response);
      if (response.success === 1) {
        console.log(response);
        const requestList = [...response?.result?.data[0]]; // list
        const mappedRequest = requestList.map((r) => {
          const requestCopy = { ...r };
          requestCopy.CreatedOn = new Date(
            requestCopy.CreatedOn
          ).toDateString();
          requestCopy.LastModifiedOn = new Date(
            requestCopy.LastModifiedOn
          ).toDateString();
          return requestCopy;
        });

        setDashboardRequest(mappedRequest);
        setdashboardFilterRequest(mappedRequest);
        console.log("setDashboardRequest" + JSON.stringify(dashboardRequest));
        totalCount.current = response?.result?.data[1][0].totalCount;
        totalApprovedCount.current = response?.result?.data[2][0].totalApprovedCount;
        totalPendingCount.current = response?.result?.data[3][0].totalPendingCount;
        totalNotApprovedCount.current = response?.result?.data[4][0].totalNotApprovedCount;   
        
        //fOR GRAPH
        const grapAllDataArray = response?.result?.data[5].map((x)=>x.Total)
        const grapPendingDataArray = response?.result?.data[6].map((x)=>x.Total)        
        const grapRejectedDataArray = response?.result?.data[7].map((x)=>x.Total)
        const grapApprovedDataArray = response?.result?.data[8].map((x)=>x.Total)
        

        setgrapAllData(grapAllDataArray)
        setgrapPendingData(grapPendingDataArray)
        setgrapRejectedData(grapRejectedDataArray)
        setgrapApprovedData(grapApprovedDataArray)
        //console.log(grapPendingData)


      } else {
        console.log("else..");
        setDashboardRequest([]);
        setdashboardFilterRequest([]);
      }
    } catch (err) {
      console.log(err);
      if (err?.error == 'Invalid Token') {
        localStorage.removeItem("accessToken");
        navigate("/login");   
      }
    }
  };

  return (
    <>
      <Profile />
      <header className="Dashboard_header">
        {/* <div className="page_title"> */}
        {/* <div className="navigation d-flex">
            <Home /> <span className="separator">/</span>
            <div className="text">Dashboard</div>
          </div> */}
        {/* <div className="title">Dashboard</div> */}
        {/* </div> */}

        <div className="dashboard_dropdown">
          <RequestDropdown handleChange={handleChange} />
          <DateRange />
        </div>
      </header>

      <div className="dashboard_Cards">
        <div className="card-list">
          <CardSummary
            requestType="All Request"
            className="__all"
            num={totalCount.current}
            icon={<Receipt />}
            type="all"
          />         
          <CardSummary
            requestType="Pending"
            className="__pending"
            num={totalPendingCount.current}
            icon={<PendingIcon />}
            type="SSRV"
            StatusId={'1'}
          />
          <CardSummary
            requestType="Completed"
            className="__approved"
            num={totalApprovedCount.current}
            icon={<CheckCircleOutlineIcon />}
            type="COMPLTD"
            StatusId={'10'}
          />
           <CardSummary
            requestType="Not Approved"
            className="__notapproved"
            num={totalNotApprovedCount.current}
            icon={<DoNotDisturbOnIcon />}
            type="RJCT"
            StatusId={'4'}
          />
        </div>
      </div>
      <div className="__graph">
        <div className="graph-list d-flex">
          <div className="card">
            <div className="__graph1 all">
              <VerticalBarChart
                chart={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul","Aug","Sep","Oct","Nov","Dec"],
                  datasets: {
                    label: "Sales",
                    data: grapAllData,
                  },
                }}
              />
            </div>
            <div className="summary mt-3">All Request</div>
          </div>       
          <div className="card">
            <div className="__graph1 pending">
              <VerticalBarChart
                chart={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul","Aug","Sep","Oct","Nov","Dec"],
                  datasets: {
                    label: "Sales",
                    data: grapPendingData,
                  },
                }}
              />
            </div>
            <div className="summary mt-3">Pending Request</div>
          </div>
          <div className="card">
            <div className="__graph1 approved">
              <VerticalBarChart
                chart={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul","Aug","Sep","Oct","Nov","Dec"],
                  datasets: {
                    label: "Sales",
                    data: grapApprovedData,
                  },
                }}
              />
            </div>
            <div className="summary mt-3">Completed Request</div>
          </div>

          <div className="card">
            <div className="__graph1 not-approved">
              <VerticalBarChart
                chart={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul","Aug","Sep","Oct","Nov","Dec"],
                  datasets: {
                    label: "Sales",
                    data: grapRejectedData,
                  },
                }}
              />
            </div>
            <div className="summary mt-3">Not Approved</div>
          </div>
        </div>
      </div>
      <div className="__request-list">
        <div className="table-body">
          <div className="table-header">
            <span>Request Detail</span>
          </div>
          {totalCount.current > 0 ? (
            <MyTable data={dashboardFilterRequest}></MyTable>
          ) : (
            <span className="dashboardNoData">No Data to display</span>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
