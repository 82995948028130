import { DateRange, TaskAlt } from "@mui/icons-material";
import { Box, Popover, Popper } from "@mui/material";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import actionIcon from "../../assets/dashboardTimeline.jpg";

const TableRow = ({ val, key }) => {
  const [actionPopup, setActionPopup] = useState(null);

  let styles = {
    statusBorder: {
      border: "1px solid ",
    },
  };

  return (
    <>
      <tr key={key}>
      
        <td className="report" style={{ width: "23%", borderLeft:  `5px solid ${val.BackgroundColor}` }}>
          <div className="d-flex">
            <div className="icon">
              <TaskAlt />
            </div>
            <div>
              <div className="ind-num">{val.Id}</div>
              <div className="ind_text"> {val.CompanyName} </div>
            </div>
          </div>
        </td>
        <td style={{ width: "13%" }}>
          <div>
            Name: <br></br> {val.FirstName}
          </div>
          {/* <div>{val.securityBranch}</div> */}
        </td>
        <td className="date" style={{ width: "17%" }}>
          <div className="">
            <DateRange />
            <span>
              Created On:<br></br>
              {val.CreatedOn}
            </span>
          </div>
          {/* <div className="d-flex">            
            <span>Created By: {val.CreatedBy}</span>
          </div> */}
        </td>
        <td className="date" style={{ width: "17%" }}>
          <div className="">
            <DateRange />
            <span>
              Updated On:<br></br> {val.LastModifiedOn}
            </span>
          </div>
        </td>
        <td style={{ width: "18%" }}>
          <div className="status"  style={{ border: `1px solid ${val.BackgroundColor}` }}>
           <span style={{color:`${val.FontColor}`, textTransform:'uppercase'}}> {val.StatusName}</span>
          </div>
        </td>
        <td className="action" style={{ width: "20%" }}>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <img src={actionIcon} alt="location-png" />
            <button
              onClick={(e) => setActionPopup(e.currentTarget)}
              style={{ textTransform: "uppercase", color: "#3892ee" }}
            >
              VIEW / ACTION
            </button>
            {/* {Boolean(actionPopup) && <div className="arrow"></div>} */}
          </div>
          {val.actions}
        </td>
      </tr>

      <Popover
        className="timeline-base"
        id="report-filter"
        open={Boolean(actionPopup)}
        anchorEl={actionPopup}
        onClose={() => {
          setActionPopup(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "10px",
            "&::before": {
              backgroundColor: "white",
              borderLeft: "1px solid #cccccc59",
              borderTop: "1px solid #cccccc59",
              content: '""',
              display: "block",
              position: "absolute",
              width: 12,
              height: 12,
              top: -6,
              transform: "rotate(45deg)",
              left: "calc(95.3% - 6px)",
            },
          }}
        />

        <div className="time-line">
          <div className="d-flex">
            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done" style={{'background-color': 'white'}}></div>
                <div className="prog done">
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    1
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Request Submitted
                </Link>
              </div>
            </div>
            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className="prog done" style={{backgroundColor:`${val.BackgroundColor}`}}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    2
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                {" "}
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  {val.StatusCode = "SSRV" ? "Under Process" : val.StatusName }
                </Link>
              </div>
            </div>
            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className={`prog ${val.QuotationStatusCode}-Qtn`} >
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    3
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                {" "}
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Quotation - {
                    val.QuotationStatusCode == "ENBLD" ? "Recieved" : val.QuotationStatus
                    }
                </Link>
              </div>
            </div>
            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className={`prog ${val.ProposalStatusCode}-Prp`}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    4
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Proposal - { val.ProposalStatusCode == "ENBLD" ? "Recieved" : val.ProposalStatus}
                </Link>
              </div>
            </div>
            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className="prog done" style={{backgroundColor:`${val.BackgroundColorInsSch}`}}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    5
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Inspection Scheduled
                </Link>
              </div>
            </div>

            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className="prog done" style={{backgroundColor:`${val.BackgroundColorRprt}`}}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    6
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Inspection Done
                </Link>
              </div>
            </div>

            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className="prog done" style={{backgroundColor:`${val.BackgroundColorRprt}`}}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    7
                  </Link>
                </div>
                <div className="line done"></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  Prepare Report
                </Link>
              </div>
            </div>

            <div className="progress-bar1 text-center">
              <div className="d-flex">
                <div className="line done"></div>
                <div className="prog"  style={{backgroundColor:`${val.EndProcessColor}`}}>
                  <Link
                    to={`/editRequest/${val.Id}`}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    8
                  </Link>
                </div>
                <div className="line done" style={{'background-color': 'white'}}></div>
              </div>
              <div className="name">
                <Link
                  to={`/editRequest/${val.Id}`}
                  style={{ textDecoration: "none", color: "#2d3748" }}
                >
                  End Process
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default TableRow;
