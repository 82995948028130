import { BASE_URL, RequestAPI } from "./httpRequests";
import CryptoJS from "crypto-js";

export const getValuationRequestData = (categories) => {
  let payload = categories.map(key => {
    return CryptoJS.AES.encrypt(key, "secret-key-hamptons").toString();
  })

  return RequestAPI(BASE_URL + `/master/getmasterdata`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
};
