import React, { useState, useEffect } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { Image } from "antd";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Emmar_logo from "../../assets/hmptons-logo.png";
import bgVideo from "../../assets/hero-landscape.mp4"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import poster from "../../assets/hero-landscape-poster.jpg"
import "./style.css";

import { resetPassword } from "../../services/loginService";

import { toast } from "react-toastify";

const ResetPassword = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get('token');
  const [isMobile, setIsMobile] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: ""
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (token) {
      var decoded = jwt_decode(token);
      if (decoded.email) {
        setEmail(decoded.email)
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const submitForm = (e) => {
    if (loading) return;
    e.preventDefault();
    const errors = validate(formValue);
    setFormErrors(errors);
    console.log({errors});
    setIsSubmit(true);
    // Check if there are any form errors
    if (Object.keys(errors).length === 0) {
     
      setLoading(true)
      resetPassword({ ...formValue, email })
        .then((response) => {
          setLoading(false)
          console.log({ response });
          if (response.status === 200) {
            setFormValue(initialValues)
            toast.success("Password Updated successfully!", {
              toastId: "form-success",
              onClose: () => {
                setIsSubmit(true);
                setIsToastOpen(false);
              },
            });
            setIsToastOpen(true);
            navigate("/login");
          } else {
            // Show error message using React Toastify
            toast.error(response.message, {
              toastId: "login-error",
              onClose: () => {
                setIsSubmit(false);
                setIsToastOpen(false);
              },
            });
            setIsToastOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error("An error occurred. Please try again later.", {
            toastId: "login-error",
            onClose: () => {
              setIsToastOpen(false);
            },
          });
          setIsToastOpen(true);
        });
    }
  };

  const validate = (values) => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (values.password.length === 0) {
      errors.password = "Password is Required";
    } else if (values.password.length < 7) {
      errors.password = "Password should be more than 6 digit";
    } else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Should contain 1 digit, 1 uppercase, 1 lowercase and 1 special character !@#$%^&*";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is Required";
    } else if (values.confirmPassword.length < 6) {
      errors.confirmPassword = "Confirm Password should be more than 6 digit";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Did not match with Password";
    }
    return errors;
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      // backgroundImage: `url(${dubai})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "2rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "30%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    mobForm:{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "1rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "85%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    input: {
      margin: "1rem 0",
      width: "100%",
      borderColor: "#333",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    button: {
      margin: "1rem 0",
      width: "50%",
      backgroundColor: isSubmit || isToastOpen ? "grey" : "",
    },
    link: {
      marginTop: "1rem",
      color: "black",
    },
    logo: {
      width: "190px",
      height: "80px",
      opacity: "50%"
    },
    video: {
      width: "100%"
    },
    divider: {
      width: "80%"
    }
  };

  return (
    <div style={styles.container} className="login-form">
      <video className="" id="myVideo" autoPlay poster={poster} loop=""
      >
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div style={isMobile ?styles.mobForm: styles.form}>
        <Image style={styles.logo} src={Emmar_logo} />
        <hr style={styles.divider} />
        <div className="title" style={{ marginBottom: 20, marginTop: 10 }}> Reset Your Password </div>

        <TextField
          style={{
            margin: 10,
            borderColor: formErrors.password ? "red" : "#333",
            outlineColor: formErrors.password ? "red" : "",
          }}
          label={!formValue.password ? "Password" : ""}
          variant="filled"
          margin="normal"
          InputProps={{
            endAdornment: showPassword ? (
              <VisibilityOffIcon
                style={{ color: "black", cursor: "pointer" }}
                onClick={toggleShowPassword}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#1a202c78", cursor: "pointer" }}
                onClick={toggleShowPassword}
              />
            ),
            style: { width: "100%" },
            type: showPassword ? "text" : "password",
          }}
          name="password"
          value={formValue.password}
          onChange={handleChange}
          autoComplete="off"
        />

        {isSubmit && formErrors.password !== "" && (
          <span className="lbl-error">{formErrors.password}</span>
        )}

        <TextField
          style={{
            margin: 10,
            borderColor: formErrors.confirmPassword ? "red" : "#333",
            outlineColor: formErrors.confirmPassword ? "red" : "",
          }}
          label={!formValue.confirmPassword ? "Confirm Password" : ""}
          variant="filled"
          margin="normal"
          InputProps={{
            endAdornment: showConfirmPassword ? (
              <VisibilityOffIcon
                style={{ color: "black", cursor: "pointer" }}
                onClick={toggleShowConfirmPassword}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#1a202c78", cursor: "pointer" }}
                onClick={toggleShowConfirmPassword}
              />
            ),
            style: { width: "100%" },
            type: showConfirmPassword ? "text" : "password",
          }}
          name="confirmPassword"
          value={formValue.confirmPassword}
          onChange={handleChange}
          autoComplete="off"
        />

        {isSubmit && formErrors.confirmPassword !== "" && (
          <span className="lbl-error">{formErrors.confirmPassword}</span>
        )}

        <Button
          className="btn-login"
          variant="contained"
          color="primary"
          onClick={submitForm}
        // disabled={isSubmit || isToastOpen}
        >

          {loading ? <CircularProgress size={24} color="inherit" /> : "Reset Password"}
        </Button>

      </div>
    </div>
  );
};

export default ResetPassword;
