const EMIRATE = "Emirate";
const TYPE_OF_PROPERTY = "Type_of_Property";
const PURPOSE_OF_VALUATION = "Purpose_of_Valuation";
const APPROVAL_ACTION = "ApprovalAction";
const INSPECTION_TYPE = "InspectionType";
const TYPE_OF_NON_INSPECTION_PROPERTY = "Type_of_Non_Inspection_Property";
const INSPECTION_TYPE_PROPERTY = "Inspection Type Property";
const PROBLEM_TYPE = "ProblemType";

export {
  EMIRATE,
  TYPE_OF_PROPERTY,
  PURPOSE_OF_VALUATION,
  APPROVAL_ACTION,
  INSPECTION_TYPE,
  INSPECTION_TYPE_PROPERTY,
  TYPE_OF_NON_INSPECTION_PROPERTY,
  PROBLEM_TYPE
};
