import React, { useState } from "react";
import Dropzone from "react-dropzone";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Upload } from "@mui/icons-material";
import { toast } from "react-toastify";

const allowFileExtensions = [
  "application/msword",
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

const allowedFileNameExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'doc', 'docx',]


const FileUpload = ({ onFilesSelected, uploadedFile, isDisabled }) => {
  const [files, setFiles] = useState([]);

  const handleDrop = (acceptedFiles) => {
    let fileNotAllowed = false;
    const filteredAcceptedFiles = acceptedFiles.filter(file => {
      const fileExtension = file?.name?.split('.').pop();
      if (allowFileExtensions.includes(file.type) || allowedFileNameExtensions.includes(fileExtension)) {
        return file
      } else {
        fileNotAllowed = true;
        return null
      }
    });
    if (fileNotAllowed) {
      toast.error("Please upload pdf, doc or image files only.", { toastId: "login-error" });
      return;
    }

    const selectedFile = filteredAcceptedFiles.length > 0 ? filteredAcceptedFiles[0] : null;
    setFiles(selectedFile ? [selectedFile] : []);
    onFilesSelected(selectedFile ? [selectedFile] : []);

  };

  return (
    <div className="dropzone-container">
      <Dropzone
        onDrop={handleDrop}
        disabled={isDisabled} // make the component read-only
        initialFiles={[uploadedFile || "default_filename.txt"]} // set a default filename
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            <div className="upl">
              <Upload />
              <div className="name">
                {files.length > 0
                  ? ''
                  // ? files[0]?.name?.length > 50 ? `${files[0]?.name?.substring(0, 50)}...`: files[0]?.name
                  : uploadedFile || "Select File"}{" "}
                {/* show file name */}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      {files.length > 0 && (
        <ul
          style={{
            display: "flex",
            width: "100%",
            paddingLeft: "0.5em",
            alignItems: "center",
          }}
        >
          <AttachmentIcon sx={{ marginRight: "5px" }}></AttachmentIcon>
          {files.map((file) => (
            <li key={file.name} style={{ marginTop: 10 }}>
              {
                file?.name?.length > 32 ? `${file?.name?.substring(0, 32)}...` : file?.name
              }
              {/* - {file.size} bytes */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FileUpload;
