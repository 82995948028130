import React from "react";
import { Link } from "react-router-dom";
import "./NotFoundPage.css";
import { useSpring, animated } from "react-spring";

function NotFoundPage() {
  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  });

  return (
    <div className="not-found-container">
      <animated.h1 style={animation}>Oops!</animated.h1>
      <animated.p style={animation}>
        We couldn't find the page you're looking for.
      </animated.p>
      <animated.p style={animation}>404</animated.p>
      <Link to="/dashboard" className="btn_goback">
        Go back
      </Link>
    </div>
  );
}
//Updated for checking cicd
//Updated for checking cicd 2
//Updated for checking cicd 3
//Updated for checking cicd 4
//Updated for checking cicd 4
//Updated for checking cicd 5
//Updated for checking cicd 6
//Updated for checking cicd 7
//Updated for checking cicd 8
export default NotFoundPage;
