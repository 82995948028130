import React, { useState, useRef, useEffect, useContext } from "react";
import { Email } from "@mui/icons-material";
import { TextField, Button, CircularProgress } from "@mui/material";
import { Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Emmar_logo from "../../assets/hmptons-logo.png";
import "../Login/login.css";
import dubai from "../../assets/loginPageBg.jpg";
import bgVideo from "../../assets/hero-landscape.mp4"
import poster from "../../assets/hero-landscape-poster.jpg"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { loginUser } from "../../services/loginService";
import { AuthContext } from "../../Auth/AuthContext";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha"

const Login = () => {
  document.title = 'Hamptons - login'
  const reCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED;
  const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const captchaRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const submitForm = (e) => {
    if (loading) return;
    e.preventDefault();
    const errors = validate(formValue);
    setFormErrors(errors);
    setIsSubmit(true);
    setLoading(true)
    let token = null
    if (reCaptchaEnabled == 'true') {
      token = captchaRef?.current?.getValue();
    }
    if (!token) {
      setLoading(false)
      toast.error('reCaptcha Validation in required', {
        toastId: "login-error",
        onClose: () => {
          setIsSubmit(false);
          setIsToastOpen(false);
        },
      });
      return;
    }

    // Check if there are any form errors
    if (Object.keys(errors).length === 0) {
      loginUser({ ...formValue, token })
        .then((response) => {
          setLoading(false)
          captchaRef?.current?.reset();
          if (response.status === 200) {
            localStorage.setItem("accessToken", response.token);

            var decoded = jwt_decode(response.token);
            const userObj = {
              userId: decoded.userId,
              emailaddress: decoded.email,
              userName: decoded.userName,
            };

            console.log("decoded:", decoded);
            localStorage.setItem("USER_INFO", JSON.stringify(userObj));
            localStorage.setItem("accessToken", response.token);
            login();

            // Redirect to dashboard
            navigate("/dashboard");
          } else {
            // Show error message using React Toastify
            navigate("/dashboard");
            toast.error(response.message, {
              toastId: "login-error",
              onClose: () => {
                setIsSubmit(false);
                setIsToastOpen(false);
              },
            });
            setIsToastOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false)
          // Show error message using React Toastify
          toast.error("An error occurred. Please try again later.", {
            toastId: "login-error",
            onClose: () => {
              // setIsSubmit(false);
              //  setIsToastOpen(false);
            },
          });
          setIsToastOpen(true);
        });
    } else {
      setLoading(false)
      // Show error message for form validation errors
      // toast.error("Please fix the form errors.", {
      //   toastId: "form-error",
      //   onClose: () => {
      //     //  setIsSubmit(false);
      //     // setIsToastOpen(false);
      //   },
      // });
      // setIsToastOpen(true);
    }
  };
console.log("REACT_APP_RECAPTCHA_SITE_KEY",REACT_APP_RECAPTCHA_SITE_KEY)
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    //password
    if (values?.password?.length === 0) {
      errors.password = "Required";
    }
    if (values?.email?.length === 0) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Not a valid email";
    }
    return errors;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      // backgroundImage: `url(${dubai})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "2rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "30%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    mobForm:{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "1rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "85%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    input: {
      margin: "1rem 0",
      width: "100%",
      borderColor: "#333",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    button: {
      margin: "1rem 0",
      width: "50%",
      backgroundColor: isSubmit || isToastOpen ? "grey" : "",
    },
    link: {
      marginTop: "1rem",
      color: "black",
    },
    logo: {
      width: "190px",
      height: "80px",
      opacity: "50%"
    },
    video: {
      width: "100%"
    },
    divider: {
      width: "80%"
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={styles.container} className="login-form">
      <video className="" id="myVideo" autoPlay poster={poster} loop=""
      >
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div style={isMobile ?styles.mobForm: styles.form}>
        <Image style={styles.logo} src={Emmar_logo} />
        <hr style={styles.divider} />
        <div className="title"> Login </div>

        <TextField
          placeholder="Email"
          style={{
            borderColor: formErrors.email ? "red" : "#333",
            outlineColor: formErrors.email ? "red" : "",
          }}
          margin="normal"
          InputProps={{
            endAdornment: <Email style={{ color: "#1a202c78" }} />,
            style: { width: "100%" },
          }}
          name="email"
          value={formValue.email}
          onChange={handleChange}
          autoComplete="off"


        />
        {isSubmit && formErrors.email !== "" && (
          <span className="lbl-error">{formErrors.email}</span>
        )}

        <TextField
          placeholder="Password"
          style={{
            borderColor: formErrors.password ? "red" : "#333",
            outlineColor: formErrors.password ? "red" : "",
          }}
          margin="normal"
          InputProps={{
            endAdornment: showPassword ? (
              <VisibilityOffIcon
                style={{ color: "black", cursor: "pointer" }}
                onClick={toggleShowPassword}
              />
            ) : (
              <VisibilityIcon
                style={{ color: "#1a202c78", cursor: "pointer" }}
                onClick={toggleShowPassword}
              />
            ),
            style: { width: "100%" },
            type: showPassword ? "text" : "password",
          }}
          name="password"
          value={formValue.password}
          onChange={handleChange}
          autoComplete="off"
        />

        {isSubmit && formErrors.password !== "" && (
          <span className="lbl-error">{formErrors.password}</span>
        )}

        {reCaptchaEnabled == 'true' &&
          <div style={{ margin: 20 }}>
            <ReCAPTCHA
              sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
              
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              ref={captchaRef}
            // onChange={onChange}
            />
          </div>}
        <div className="forget-pswd">
          <Link to="/forgetPassword">Forget Password?</Link>
        </div>
        <Button
          className="btn-login"
          variant="contained"
          color="primary"
          onClick={submitForm}
        // disabled={isSubmit || isToastOpen}
        >

          {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
        </Button>
        <div style={styles.link} className="d-flex">
          <p className="fs-6 pr-2" style={{ color: "rgb(255 255 255 / 75%)" }}>Not registered?</p>
          <Link to="/signup">Register</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
