import TableRow from "../TableRow";

const data = [
  {
    reportText: "work completion report",
    reportValue: 2000,
    security: "World security",
    securityBranch: "Arabian ranches-2",
    createdDate: "created  july 2023 WCR",
    updatedDate: "updated  13 july 2023",
    gender: "Male",
    status: "Pending",
  },
  {
    reportText: "work completion report 2",
    reportValue: 2200,
    security: "World security",
    securityBranch: "Arabian ranches-2",
    createdDate: "created  july 2023 WCR",
    updatedDate: "updated  13 july 2023",
    gender: "Male",
    status: "Pending",
  },
];

function MyTable({data}) {
  return (
    <table>
      {data.map((val, key) => {
        return <TableRow val={val} key={key} />;
      })}
    </table>
  );
}

export default MyTable;
