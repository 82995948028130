import React from "react";
import { DatePicker, Space } from "antd";

const DateRange = () => {
  const { RangePicker } = DatePicker;

  return (
    <>
      <Space direction="vertical" size={12} wrap="false">
        <RangePicker />
      </Space>
    </>
  );
};

export default DateRange;
