export const BASE_URL = getBaseUrl();
export const RequestAPI = async (url, options) => {
  const headers = new Headers();
  if (!(options.body instanceof FormData)) {
    headers.append("Content-Type", "application/json");
  }
  if (localStorage.getItem("accessToken")) {
    console.log(localStorage.getItem("accessToken"), "token");
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("accessToken") // Add a space after "Bearer"
    );
    if (options.body instanceof FormData) {
      options.body.append(
        "Authorization",
        "Bearer " + localStorage.getItem("accessToken") // Add a space after "Bearer"
      );
    }
  }

  const defaults = { headers: headers };

  options = Object.assign({}, defaults, options);

  console.log(options, "options of headers");

  return fetch(url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return Promise.resolve(json);
    })
  );
};

export function getBaseUrl() {
  // debugger;
  let url = window.location.hostname;
  console.log("url" + url);
  // alert(url);
  let BASE_URL = "";
  try {
    if (url === 'hamptonsclient-stage.azurewebsites.net') {
      BASE_URL = process.env.REACT_APP_STG_URL;
    } else if (url === 'hamptonspropevalclient.azurewebsites.net') {
      BASE_URL = process.env.REACT_APP_PROD_URL;
    }  else if (url === 'propertyevaluation.hamptons.ae') {
      BASE_URL = process.env.REACT_APP_PROD_URL;
    }else {
      BASE_URL = process.env.REACT_APP_LOCAL;
  //  BASE_URL = process.env.REACT_APP_STG_URL;
  //  BASE_URL = process.env.REACT_APP_PROD_URL;
      console.log({BASE_URL});
    }
  } catch (error) {}

  return BASE_URL;
}


export const HttpGet = async(url) =>{

  const headers = new Headers();
  const token = localStorage.getItem("accessToken")
  if (!token) {
    window.location.href = '/login'; 
  }
    headers.append(
      "Authorization",
      "Bearer " + token // Add a space after "Bearer"
    );  

  const options = {headers }

  console.log(options);

  return fetch(url, options).then((response) =>
  response.json().then((json) => {
    if (!response.ok) {
      return Promise.reject(json);
    }
    return Promise.resolve(json);
  })
);
}