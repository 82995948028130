import React, { useEffect, useState, useContext } from "react";
// import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import MultipleSelect from "../../UI/MultipleSelectionDropdown";
import SingleSelect from "../../UI/SingleSelectDropdown";
import { getValuationRequestData } from "../../services/masterDataService";
import { getCountryData } from "../../services/getCountryCode";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PhoneInput from "react-phone-input-2";
import {
  EMIRATE,
  TYPE_OF_PROPERTY,
  PURPOSE_OF_VALUATION,
  APPROVAL_ACTION,
} from "../../constants";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import FileUpload from "../../components/FileUpload";
import "./request.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../services/httpRequests";
import { BASE_URL, RequestAPI, HttpGet } from "../../services/httpRequests";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import CustomLoader from "../../components/CustomLoader";

const EditRequest = (param) => {
  const initialValues = {};
  const [formValue, setFormValue] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [approvalActions, setApprovalActions] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [RequestData, SetRequestData] = useState([]);
  let [isDisabled, setIsDisabled] = useState(false);
  //   const [RequestFormData, SetRequestFormData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const { loading, setLoading } = useContext(AuthContext);

  let RequestedFormData = [];
  let UploadedDocData = [];
  let RequestedEmirateData = [];
  let RequestedPropertyTypeData = [];
  let RequestedPurposeOfValuationData = [];
  let GeneratedQuotationData = [];
  let GeneratedProposal = [];
  let generatedCaseId;
  const { requestId } = useParams();
  let RequestedTypeOfProperty = [];

  const navigate = useNavigate();

  const categories = [
    EMIRATE,
    TYPE_OF_PROPERTY,
    PURPOSE_OF_VALUATION,
    APPROVAL_ACTION,
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getValuationRequestData(categories);

        console.log("getmasterDataResponse:", response?.data);

        const data = response?.data;

        const [ApprovalActions] = await Promise.all([
          data
            .filter(({ category }) => category === APPROVAL_ACTION)
            .map((item) => ({
              id: item.id,
              value: item.value,
              label: item.value,
            })),

          //   countryData.map((item) => ({
          //     id: item.CountryId,
          //     value: item.CountryName,
          //     label: item.CountryName,
          //   })),
        ]);

        // setApprovalActions(
        //   ApprovalActions.map((item) => {
        //     if (item.label === "Approved") {
        //       return { ...item, label: "Yes" };
        //     } else if (item.label === "Rejected") {
        //       return { ...item, label: "No" };
        //     } else {
        //       return item;
        //     }
        //   })
        // );
        // setCountryCodes(countryCodesData);
        setApprovalActions(ApprovalActions);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const responseReq = await HttpGet(
          BASE_URL + `/request/get-valuation/${requestId}`
        );
        console.log("valuationRequestData:", responseReq);
        setLoading(false);
        setIsPageLoaded(true);
        if (responseReq?.result[0]?.length === 0) {
          toast.error("Request does not exist", {
            toastId: "form-success",
            onClose: () => {
              setIsSubmit(false);
            },
          });
        } else {
          if (responseReq?.success == 1) {
            SetRequestData(responseReq?.result);
          }
          console.log("requestData:", responseReq);
        }
      } catch (error) {
        console.error(error);
        toast.error(error?.result?.err, {
          toastId: "form-success",
          onClose: () => {
            setIsSubmit(false);
          },
        });
      }
    };
    fetchData();
  }, []);

  console.log("RequestData in State", RequestData);

  if (RequestData?.length != 0) {
    const [RequestFormData, DocData, EmirateData, PropertyTypeData] =
      RequestData;
    RequestedFormData = { ...RequestFormData[0] };
    GeneratedQuotationData = DocData?.filter((doc) => {
      if (doc.DocumentCode === "QTION") {
        return doc;
      } else {
        return false;
      }
    });
    GeneratedProposal = DocData?.filter((doc) => {
      if (doc.DocumentCode === "PRPSL") {
        return doc;
      } else {
        return false;
      }
    });

    UploadedDocData = DocData?.filter((doc) => {
      if (!(doc.DocumentCode === "QTION" || doc.DocumentCode === "PRPSL")) {
        return doc;
      } else {
        return false;
      }
    });

    RequestedEmirateData = EmirateData?.map((item, index) => {
      const id = index + 1 + "";
      const value = item.Emirate;
      const label = item.Emirate;
      return { id, value, label };
    });

    RequestedPropertyTypeData = PropertyTypeData?.map((item, index) => {
      const id = index + 1 + "";
      const value = item.PropertyType;
      const label = item.PropertyType;
      return { id, value, label };
    });

    const {
      RequestCreator,
      FirstName,
      CaseId,
      TypeOfProperty,
      PurposeOfValuation,
    } = RequestedFormData;

    generatedCaseId = CaseId;

    RequestedPurposeOfValuationData = [
      {
        value: PurposeOfValuation,
        label: PurposeOfValuation,
      },
    ];

    RequestedTypeOfProperty = [
      {
        value: TypeOfProperty,
        label: TypeOfProperty,
      },
    ];
  }

  const [selectedFiles, setSelectedFiles] = useState({});

  const handleFilesSelected = (files, key, documentCode) => {
    const updatedFiles = files.map((file) => {
      const newFile = new File([file], file.name, { type: file.type });
      newFile.documentCode = documentCode;
      return newFile;
    });
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [key]: updatedFiles[0],
    }));
  };

  const handleQuotationAction = (selectedOptions) => {
    setFormValue({
      selectedQuotationAction: selectedOptions?.value || "",
    });
  };
  const handleProposalAction = (selectedOptions) => {
    setFormValue({
      selectedProposalAction: selectedOptions?.value || "",
    });
  };

  console.log("formValueEdit:", formValue);
  console.log("formFilesEdit:", selectedFiles);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (formValue?.selectedProposalAction == "Approved" &&  !Object.keys(selectedFiles).length ) {
      toast.error("Please upload Proposal Documents", {
        toastId: "form-success",
        onClose: () => {
          setIsSubmit(false);
        },
      });
      return;
    }

    if (
      GeneratedQuotationData?.length !== 0 &&
      (GeneratedQuotationData[0]?.IsUploadRequired ||
        GeneratedQuotationData[0].IsApprovalRequired) &&
      GeneratedQuotationData[0].StatusCode !== "SSRV"
    ) {
      formData.append("DocumentCode", "QTION");
    }

    if (
      GeneratedProposal?.length !== 0 &&
      (GeneratedProposal[0]?.IsUploadRequired ||
        GeneratedProposal[0]?.IsApprovalRequired)
    ) {
      formData.append("DocumentCode", "PRPSL");
    }

    formData.append(
      "userId",
      JSON.parse(localStorage.getItem("USER_INFO")).userId
    );

    formData.append(
      "RequestCreatorId",
      JSON.parse(localStorage.getItem("USER_INFO")).emailaddress
    );

    formData.append(
      "userName",
      JSON.parse(localStorage.getItem("USER_INFO")).userName
    );

    formData.append("CaseId", generatedCaseId);

    for (const key in formValue) {
      formData.append(key, formValue[key]);
    }

    for (const key in selectedFiles) {
      if (selectedFiles.hasOwnProperty(key)) {
        formData.append("documents", selectedFiles[key]);
      }
    }

    if (Object.keys(errors).length === 0) {
      setIsDisabled(true);
      setIsSubmitting(true);
      try {
        const BASE_URL = getBaseUrl();
        const response = await axios.put(
          `${BASE_URL}/request/update-valuation?id=${requestId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );

        setIsDisabled(false);
        setIsSubmitting(true);
        console.log("response:", response);

        if (response?.data?.success == 1) {
          setIsDisabled(true);
          toast.success(
            `Request updated successfully for  Application Id: ${requestId}`,
            {
              toastId: "form-success",
              position: "top-center",
              autoClose: 3500,
              onClose: () => {
                navigate("/Dashboard");
                  setIsSubmit(false);
                // setTimeout(() => {
                //   navigate("/Dashboard");

                //   setIsSubmit(false);
                // }, 3700);
              },
            }
          );
        } else {
          toast.error("An error occurred. Please try again later.", {
            toastId: "form-success",
            onClose: () => {
              setIsSubmit(false);
            },
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("An error catched. Please try again later.", {
          toastId: "form-error",
          onClose: () => {
            setIsSubmit(false);
          },
        });
      }
    } else {
      toast.error("Please fix the form errors.", {
        toastId: "form-error",
        onClose: () => {
          setIsSubmit(false);
        },
      });
    }
  };

  const errors = {};

  const validate = (values) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const nameRegex = /^[A-Za-z']+$/;
    const phoneRegex = /^\+\d{1,}[0-9]{1,}$/;
    // const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\./0-9]*$/g;

    if (values?.companyName?.length === 0) {
      errors.companyName = "Required";
    } else if (!nameRegex.test(values.companyName)) {
      errors.companyName = "Should contain only alphabet";
    }

    if (values?.individualName?.length === 0) {
      errors.individualName = "Required";
    } else if (!nameRegex.test(values.individualName)) {
      errors.individualName = "Should contain only alphabet";
    }

    //email
    if (values?.email?.length === 0) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Not a valid email";
    }

    //Phone & Telephone Numbers
    if (values?.phonenumber?.length === 0) {
      errors.phonenumber = "Required";
    } else if (values?.phonenumber?.length > 15) {
      errors.phonenumber = "Enter valid number";
    }
    //valuationOption
    if (values?.selectedValuationOption?.length === 0) {
      errors.selectedValuationOption = "Required";
    }
    if (values?.selectedEmirateOption?.length === 0) {
      errors.selectedEmirateOption = "Required";
    }
    if (values?.selectedPropertyOption?.length === 0) {
      errors.selectedPropertyOption = "Required";
    }
    // if (values.countryId === "") {
    //   errors.countryId = "Please Select Country";
    // }

    //CountryCode
    // if (!values?.countryId == null) {
    //   errors.countryId = "Please Select Country";
    // }

    return errors;
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors)?.length === 0 && isSubmit) {
      console.log(" form Values is : " + formValue);
    }
  }, [formErrors]);

  const base64ToBlob = (base64, type = "application/octet-stream") => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  };

  async function handleDownloadFile(docId) {
    debugger;
    // const body = JSON.stringify(docId);   
    const fileType = "application/pdf";
    let payload = { docId: docId };
    console.log("handleDownloadFile called", docId);
    const BASE_URL = getBaseUrl();
    const response = await axios.post(
      `${BASE_URL}/request/_downloadfile`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    
   
    console.log("response handleDownloadFile :", response);
debugger;
    var blob = base64ToBlob(response?.data?.result, fileType);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, "_blank");
    // }
  }

  return (
    <>
      {loading ? ( // Display loader while loading data
        <CustomLoader />
      ) : (
        <>
          {isSubmitting && ( // Display loader while submitting form
            <div>
              <CustomLoader />
            </div>
          )}
          {isPageLoaded && (
            <div className="__body">
              <header className="Dashboard_header">
                <div className="page_title">
                  <div className="navigation d-flex">
                    <ChecklistIcon /> <span className="separator">/</span>
                    <div className="text">Valuation</div>
                  </div>
                  <div className="title">Edit request </div>
                </div>
              </header>
              <div className="valuation_request_Container">
                <div className="request_Form_Container">
                  <span className="title">BASIC INFO</span>
                  <div className="basic">
                    <div className="row" style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Company Name <span className="required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="companyName"
                            placeholder="3"
                            value={RequestedFormData?.CompanyName}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            First Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="individualName"
                            placeholder="Individual Name"
                            value={RequestedFormData?.FirstName}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row"  style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="individualName"
                            placeholder="Individual Name"
                            value={RequestedFormData?.LastName}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span className="required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={RequestedFormData?.ValuatorEmailAddress}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Phone Number <span className="required">*</span>
                          </label>
                          <PhoneInput
                            className="form-control"
                            placeholder="select phone number"
                            disabled={true}
                            specialLabel=""
                            value={
                              RequestedFormData?.CountryId +
                              " " +
                              RequestedFormData?.ContactNumber
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Emirates <span className="required">*</span>
                          </label>
                          {RequestedEmirateData?.length !== 0 ? (
                            <MultipleSelect
                              defaultValue={RequestedEmirateData}
                              isDisabled="true"
                              placeholder={RequestedEmirateData.join(",")}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            PropertyType <span className="required">*</span>
                          </label>
                          {RequestedTypeOfProperty?.length !== 0 ? (
                            <SingleSelect
                              defaultValue={RequestedTypeOfProperty[0]}
                              placeholder="InspectionType"
                              readOnly // make the input field read-only
                              isDisabled={true}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Property List <span className="required">*</span>
                          </label>
                          {RequestedPropertyTypeData?.length !== 0 ? (
                            <MultipleSelect
                              defaultValue={RequestedPropertyTypeData}
                              isDisabled="true"
                              placeholder="Type of Property"
                              readOnly
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                          BUA <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="propertySize"
                            placeholder="BUA"
                            value={RequestedFormData?.PropertySize}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Purpose of Valuation{" "}
                            <span className="required">*</span>
                          </label>
                          {RequestedPurposeOfValuationData?.length !== 0 ? (
                            <SingleSelect
                              defaultValue={RequestedPurposeOfValuationData[0]}
                              placeholder="select purpose of valuation"
                              readOnly // make the input field read-only
                              isDisabled={true}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{"marginBottom":"12px"}}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                          Plot Area
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="plotArea"
                            placeholder="Plot Area"
                            value={RequestedFormData?.PlotArea}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Property Address{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="property Address"
                            placeholder="Property Address"
                            value={RequestedFormData?.PropertyAddress}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="request_Form_Container mt-5">
                  <span className="title">SUPPORTING DOCUMENTS</span>
                  <div className="document">
                    <div className="file_container">
                      <Table>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#cccccc29" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Document Name
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Upload
                            </TableCell>

                            <TableCell sx={{ fontWeight: "bold" }}>
                              Comments
                            </TableCell>

                            <TableCell sx={{ fontWeight: "bold" }}>
                              Status
                            </TableCell>

                            {/* <TableCell sx={{ fontWeight: "bold" }}>
                              History
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {UploadedDocData?.map((Document) => {
                            return (
                              <TableRow>
                                <TableCell>
                                  {/* <input
                                    type="checkbox"
                                    checked
                                    className="mr-3"
                                  /> */}
                                  {Document?.DocumentName}
                                </TableCell>
                                <TableCell>
                                  <FileUpload
                                    isDisabled="true"
                                    uploadedFile={Document?.FileName}
                                    onFilesSelected={(files) =>
                                      handleFilesSelected(files, "documents")
                                    }
                                  />
                                </TableCell>

                                <TableCell>
                                  <textarea
                                    disabled
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      border: "none",
                                      padding: "8px",
                                      boxSizing: "border-box",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                    defaultValue={Document?.Comments}
                                  ></textarea>
                                </TableCell>

                                <TableCell>{Document?.StatusName}</TableCell>
                                {/* <TableCell>
                                  <HistoryIcon />
                                </TableCell> */}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>

                {/*  Quotation */}

                {GeneratedQuotationData?.length !== 0 && (
                  <div className="request_Form_Container mt-5">
                    <span className="title">QUOTATION DOCUMENTS</span>
                    <div className=" document">
                      <div className="file_container">
                        <Table>
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "#cccccc29" }}>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Document name
                              </TableCell>

                              <TableCell sx={{ fontWeight: "bold" }}>
                                Upload
                              </TableCell>

                              {!GeneratedQuotationData[0]?.Code && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Approval
                                </TableCell>
                              )}
                              {GeneratedQuotationData[0]?.Code && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  ApprovalAction
                                </TableCell>
                              )}
                              {GeneratedQuotationData[0].Comments && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Comments
                                </TableCell>
                              )}
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Status
                              </TableCell>
                              {GeneratedQuotationData[0]
                                ?.PreviewFilenetDocId && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Quotation Preview
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {/* <input
                                  type="checkbox"
                                  checked
                                  className="mr-3"
                                /> */}
                                1.Quotation
                              </TableCell>

                              <TableCell>N/A</TableCell>

                              {GeneratedQuotationData[0]
                                ?.IsApprovalRequired && (
                                <TableCell>
                                  <SingleSelect
                                    selectOptions={approvalActions}
                                    isDisabled={
                                      GeneratedQuotationData[0]?.StatusCode ===
                                        "SSRV" ||
                                      GeneratedQuotationData[0]?.StatusCode ===
                                        "DCAWC"
                                        ? true
                                        : false
                                    }
                                    defaultValue={
                                      GeneratedQuotationData[0]?.Value && {
                                        label: GeneratedQuotationData[0]?.Value,
                                      }
                                    }
                                    placeholder={
                                      <span
                                        style={{
                                          textOverflow: "ellipsis",
                                          display: "block",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          maxWidth: "100%",
                                        }}
                                        title="ApprovalAction"
                                      >
                                        Select Action
                                      </span>
                                    }
                                    handleSelect={handleQuotationAction}
                                  />
                                </TableCell>
                              )}

                              {GeneratedQuotationData[0].Comments && (
                                <TableCell>
                                  <textarea
                                    disabled
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      border: "none",
                                      padding: "8px",
                                      boxSizing: "border-box",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                    defaultValue={
                                      GeneratedQuotationData[0].Comments
                                    }
                                  ></textarea>
                                </TableCell>
                              )}
                              <TableCell>
                                {GeneratedQuotationData[0].StatusName}
                              </TableCell>
                              {GeneratedQuotationData[0]
                                ?.PreviewFilenetDocId && (
                                <TableCell>
                                  <Button
                                    onClick={() => {
                                      handleDownloadFile(
                                        GeneratedQuotationData[0]
                                          ?.PreviewFilenetDocId
                                      );
                                    }}
                                  >
                                    Click to preview
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                )}

                {/*  Proposal */}

                {GeneratedProposal?.length !== 0 && (
                  <div className="request_Form_Container mt-5">
                    <span className="title">PROPOSAL DOCUMENTS</span>
                    <div className=" document">
                      <div className="file_container">
                        <Table>
                          <TableHead>
                            <TableRow sx={{ backgroundColor: "#cccccc29" }}>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Document name
                              </TableCell>
                              {GeneratedProposal[0]?.IsUploadRequired && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Upload
                                </TableCell>
                              )}
                              {GeneratedProposal[0]?.IsApprovalRequired && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Approval
                                </TableCell>
                              )}
                              {GeneratedProposal[0].Comments && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Comments
                                </TableCell>
                              )}
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Status
                              </TableCell>
                              {GeneratedProposal[0]?.PreviewFilenetDocId && (
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Proposal Preview
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {/* <input
                                  type="checkbox"
                                  checked
                                  className="mr-3"
                                /> */}
                                1.Proposal
                              </TableCell>
                              {GeneratedProposal[0]?.IsUploadRequired && (
                                <TableCell>
                                  <FileUpload
                                    isDisabled={
                                      GeneratedProposal[0]?.StatusCode ===
                                        "DCSSRV" ||
                                      GeneratedProposal[0]?.StatusCode ===
                                        "DCAWC"
                                        ? true
                                        : false
                                    }
                                    uploadedFile={
                                      GeneratedProposal[0]?.FileName ?? ""
                                    }
                                    onFilesSelected={(files) =>
                                      handleFilesSelected(
                                        files,
                                        "proposal",
                                        "PRPSL"
                                      )
                                    }
                                  />
                                </TableCell>
                              )}
                              {GeneratedProposal[0]?.IsApprovalRequired && (
                                <TableCell>
                                  <SingleSelect
                                    isDisabled={
                                      GeneratedProposal[0]?.StatusCode ===
                                        "DCSSRV" ||
                                      GeneratedProposal[0]?.StatusCode ===
                                        "DCAWC"
                                        ? true
                                        : false
                                    }
                                    defaultValue={
                                      GeneratedProposal[0]?.Code &&
                                      GeneratedProposal[0]?.Value && {
                                        label: GeneratedProposal[0]?.Value,
                                      }
                                    }
                                    selectOptions={approvalActions}
                                    placeholder={
                                      <span
                                        style={{
                                          textOverflow: "ellipsis",
                                          display: "block",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          maxWidth: "100%",
                                        }}
                                        title="ApprovalAction"
                                      >
                                        Select Action
                                      </span>
                                    }
                                    handleSelect={handleProposalAction}
                                  />
                                </TableCell>
                              )}
                              {GeneratedProposal[0].Comments && (
                                <TableCell>
                                  <textarea
                                    disabled
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      border: "none",
                                      padding: "8px",
                                      boxSizing: "border-box",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                    defaultValue={
                                      GeneratedProposal[0]?.Comments
                                    }
                                  ></textarea>
                                </TableCell>
                              )}

                              <TableCell>
                                {GeneratedProposal[0].StatusName}
                              </TableCell>

                              {GeneratedProposal[0]?.PreviewFilenetDocId && (
                                <TableCell>
                                  <Button
                                    onClick={() => {
                                      handleDownloadFile(
                                        GeneratedProposal[0]
                                          ?.PreviewFilenetDocId
                                      );
                                    }}
                                  >
                                    Click to preview
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="request_Form_Container mt-3"  style={{ display: "flex", alignItems: "end" }}>
                  <div className="justify-content-between d-flex btn-group">
                    <div style={{"marginRight":"3px"}}>
                      <Button
                        variant="contained"
                        className="btn-cancel"
                        color="error"
                      >
                        <Link to="/dashboard">Cancel</Link>
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        className="btn-submit"
                        color="success"
                        onClick={handleSubmit}
                        disabled={
                          (GeneratedQuotationData?.length == 0 &&
                            GeneratedProposal?.length == 0) ||
                          (GeneratedQuotationData[0]?.StatusCode === "SSRV" &&
                            GeneratedProposal?.length == 0) ||
                          (GeneratedProposal?.length !== 0 &&
                            (GeneratedProposal[0]?.StatusCode === "DCSSRV" ||
                              GeneratedProposal[0]?.StatusCode === "DCAWC"))
                            ? true
                            : isDisabled ?? false
                        }
                      >
                        update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditRequest;
