import React from "react";

import Select from "react-select";
import "../../components/Request/request.css";
import { useState } from "react";

const MultipleSelect = ({
  selectOptions,
  placeholder,
  handleSelect,
  isDisabled,
  defaultValue,
  selectedValues,
  selectedOption,
}) => {
  const handleChange = (selectedOptions) => {
    console.log("multipleSelect:", selectedOptions);
    handleSelect(selectedOptions);
  };

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: "0px",
        colors: {
          ...theme.colors,
          // primary25: "hotpink",
          primary: "#347ebf87!important",
          border: "1px solid #347ebf87!important"
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          padding: "2px",
         height: "42px !important",
          // backgroundColor: "rgb(247,248,250)",
          border: "1px solid #347ebf87!important"
        }),
      }}
      isMulti
      name="colors"
      options={selectOptions}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultInputValue={defaultValue}
      defaultValue={defaultValue}
      onChange={handleChange}
      value={selectedOption}
    />
  );
};
export default MultipleSelect;
