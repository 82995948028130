import {BASE_URL, HttpGet} from './httpRequests'

export const getDashboadRequest = (userId,statusId) => {    
    let url =   BASE_URL + `/dashboard/request-data`;
    console.log(statusId);
    if(statusId){
      url = `${url}/?statusId=${statusId}`;
    }
    console.log(url);
    return HttpGet(url);
}
