import React, { useContext, useState,useEffect,Route } from "react";
import "./style.css";

import { Dashboard, Login, People, Receipt,Edit } from "@mui/icons-material";
import { AuthContext } from "../../../Auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import KeyIcon from '@mui/icons-material/Key';
import BugReportIcon from '@mui/icons-material/BugReport';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import AssessmentIcon from '@mui/icons-material/Assessment';
export const Sidebar = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  

  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();
  const { pathname } = location;
  const hideNavbar =
    pathname === "/login" || pathname === "/signup" || pathname === "/forgetPassword" || pathname === "/resetPassword" || pathname === "/";

  const handleLogout = () => {
    setTimeout(() => {
      logout();
      setActiveTab(0);
      navigate("/login");
    }, 100);
  };

  useEffect(() => {
    let hideSideBar = pathname === "/login" || pathname === "/signup" || pathname === "/forgetPassword" || pathname === "/resetPassword" || pathname === "/";
    if (hideSideBar) {
      setActiveTab(0);
    }

    if (pathname == '/dashboard')setActiveTab(0);
    if (pathname == '/valuationRequest')setActiveTab(1);
    if (pathname == '/profile')setActiveTab(2);
    if (pathname == '/changePassword')setActiveTab(3);
    if (pathname == '/raiseATicket')setActiveTab(4);
    if (pathname == '/ticketList')setActiveTab(5);
  }, [pathname, location]);

const refreshPage =()=>{
  alert('hello')
}

  return (
    <>
      {!hideNavbar && (
        <div className="__sidebar">
          <div className="__menu">
            <div className="title">HAMPTONS</div>
            <div className="__divider"></div>
            <div className="p-3">
              <p
                className={`__item ${activeTab === 0 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(0);
                  navigate("/dashboard");
                }}
              >
                <Dashboard />
                <div className="__item_title">
                  <span>Dashboard</span>
                </div>
              </p>
              <p
                className={`__item ${activeTab === 1 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(1);
                  navigate("/valuationRequest");
                }}
              >
                <RemoveFromQueueIcon />
                <div className="__item_title">
                  <span>New Request</span>
                </div>
              </p>
               {/* <p
                className={`__item ${activeTab === 2 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(2);
                  navigate("/editRequest");
                }}
              >
                <Edit />
                <div className="__item_title">
                  <span>Edit Request</span>
                </div>
              </p>  */}
              <p
                className={`__item ${activeTab === 2 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(2);
                  navigate("/profile")
                }
                
                }
              >
                <People />
                <div className="__item_title">
                  <span>My Profile</span>
                </div>
              </p>


              <p
                className={`__item ${activeTab === 3 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(3);
                  navigate("/changePassword")
                }
                
                }
              >
                <KeyIcon />
                <div className="__item_title">
                  <span>Change Password</span>
                </div>
              </p>

              <p className={`__item ${activeTab === 4 ? "active" : ""}`} onClick={() => {setActiveTab(4); navigate("/RaiseATicket")
                  
                }
                
                }
              >
                <BugReportIcon />
                <div className="__item_title">
                  <span>Raise A Ticket</span>
                </div>
              </p>

              <p
                className={`__item ${activeTab === 5 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(5);
                  navigate("/ticketList")
                }
                
                }
              >
                <AssessmentIcon />
                <div className="__item_title">
                  <span>Tickte List</span>
                </div>
              </p>

              {/* <p
                className={`__item ${activeTab === 3 ? "active" : ""}`}
                onClick={() => {
                  setActiveTab(3);
                  navigate("/signup");
                }}
              >
                <AssignmentIcon />
                <div className="__item_title">
                  <span>Sign Up</span>
                </div>
              </p> */}
              <p className={`__item ${activeTab === 6 ? "active" : ""}`} onClick={handleLogout}>
                <Login />
                <div className="__item_title" >
                  <span>Sign Out</span>
                </div>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
