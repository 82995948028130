import { Select } from "antd";
// const onChange = (value) => {
//   console.log(`selected ${value}`);
// };
const onSearch = (value) => {
  console.log("search:", value);
};
const RequestDropdown = ({handleChange}) => (
  <Select
  style={{height:"46px"}}
    // showSearch
    placeholder="Select Request Type"
    optionFilterProp="children"
    onChange={handleChange}
    onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    }
    options={[
      {
        value: "0",
        label: "All Request",
      },
      {
        value: "4",
        label: "Not Approved",
      },
      {
        value: "1",
        label: "Pending",
      },
      {
        value: "10",
        label: "Approved",
      }
     
      // {
      //   value: "Drafts",
      //   label: "Drafts",
      // },
    ]}
  />
);
export default RequestDropdown;
