import React, { useState, useEffect } from "react";
import { Email } from "@mui/icons-material";
import { TextField, Button, CircularProgress } from "@mui/material";
import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import Emmar_logo from "../../assets/hmptons-logo.png";
import bgVideo from "../../assets/hero-landscape.mp4"
import poster from "../../assets/hero-landscape-poster.jpg"
import "./style.css";

import { forgetPassword } from "../../services/loginService";

import { toast } from "react-toastify";

const ForgetPassword = () => {

  const initialValues = {
    email: "",
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const submitForm = (e) => {
    if (loading) return;
    e.preventDefault();
    const errors = validate(formValue);
    setFormErrors(errors);
    setIsSubmit(true);
    setLoading(true)

    // Check if there are any form errors
    if (Object.keys(errors).length === 0) {
      forgetPassword({ ...formValue })
        .then((response) => {
          setLoading(false)
          console.log({response});
          if (response.status === 200) {
            setFormValue(initialValues)
           toast.success("Reset Password send to your email", {
            toastId: "form-success",                    
            onClose: () => {
              setIsSubmit(true); 
              setIsToastOpen(false);
            },                    
          });
          setIsToastOpen(true);
          navigate("/login");                                                     
          } else {
            // Show error message using React Toastify
            toast.error(response.message, {
              toastId: "login-error",
              onClose: () => {
                setIsSubmit(false);
                setIsToastOpen(false);
              },
            });
            setIsToastOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false)
          // Show error message using React Toastify
          toast.error("An error occurred. Please try again later.", {
            toastId: "login-error",
            onClose: () => {
              setIsToastOpen(false);
            },
          });
          setIsToastOpen(true);
        });
    } else {
      setLoading(false)
     
    }
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
   
    if (values?.email?.length === 0) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Not a valid email";
    }
    return errors;
  };
useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      // backgroundImage: `url(${dubai})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    mobForm:{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "1rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "85%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1f1f218c",
      padding: "2rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      width: "30%",
      maxWidth: "400",
      color: "#ffffff",
      position: "fixed"
    },
    input: {
      margin: "1rem 0",
      width: "100%",
      borderColor: "#333",
      borderWidth: "1px",
      borderStyle: "solid",
    },
    button: {
      margin: "1rem 0",
      width: "50%",
      backgroundColor: isSubmit || isToastOpen ? "grey" : "",
    },
    link: {
      marginTop: "1rem",
      color: "black",
    },
    logo: {
      width: "190px",
      height: "80px",
      opacity: "50%"
    },
    video: {
      width: "100%"
    },
    divider: {
      width: "80%"
    }
  };

  return (
    <div style={styles.container} className="login-form">
      <video className="" id="myVideo" autoPlay poster={poster} loop=""
      >
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div style={isMobile ?styles.mobForm: styles.form}>
        <Image style={styles.logo} src={Emmar_logo} />
        <hr style={styles.divider} />
        <div className="title"> Forget Password </div>

        <TextField
          style={{
            borderColor: formErrors.email ? "red" : "#333",
            outlineColor: formErrors.email ? "red" : "",
            marginTop:20,
            marginBottom:20,
          }}
          label={!formValue.email ? "Email" : ""}
          variant="filled"
          margin="normal"
          InputProps={{
            endAdornment: <Email style={{ color: "#1a202c78" }} />,
            style: { width: "100%" },
          }}
          name="email"
          value={formValue.email}
          onChange={handleChange}
          autoComplete="off"


        />
        {isSubmit && formErrors.email !== "" && (
          <span className="lbl-error">{formErrors.email}</span>
        )}

       
        <Button
          className="btn-login"
          variant="contained"
          color="primary"
          onClick={submitForm}
        // disabled={isSubmit || isToastOpen}
        >

          {loading ? <CircularProgress size={24} color="inherit" /> : "Send Reset Email"}
        </Button>
       
      </div>
    </div>
  );
};

export default ForgetPassword;
