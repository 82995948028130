import React from 'react';
import { useState, useEffect } from 'react'
import NavBarProfile from "../../components/Profile/index";
import SingleSelect from "../../UI/SingleSelectDropdown";
import { getValuationRequestData } from "../../services/masterDataService";
import {
    PROBLEM_TYPE,
} from "../../constants";
import { getBaseUrl } from "../../services/httpRequests";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";


const RaiseATicket = () => {

    const [modelData, setModelData] = useState({
        id: 0,
        problemTypeId: 0,
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        problemTitle: "",
        problemSummary: "",
        createdBy: 0,
        problemType: "",
        resolutionSummary: "",
    })

    const navigate = useNavigate();
    const [selectedProblemTypeId, setSelectedProblemTypeId] = useState(0)
    const [problemTypeDataList, setProblemTypeData] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [internalTeam, setInternalTeam] = useState("");

    const [isEdit, setisEdit] = useState(false);

    const location = useLocation();
    let ticketData = location.state?.ticketData;
    const { encid } = useParams();
    var currentUser = "";

    useEffect(() => {
        if (ticketData && encid) {
            setModelData(ticketData)
            setisEdit(true)
        }
        else {
            setModelData({
                id: 0,
                problemTypeId: 0,
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                problemTitle: "",
                problemSummary: "",
                createdBy: 0,
                problemType: "",
                resolutionSummary: "",
            })
        }
        getProblemTypeData()
    }, [encid])

    const categories = [
        PROBLEM_TYPE
    ];



    const getProblemTypeData = async () => {
        debugger;
        try {
            const response = await getValuationRequestData(categories);
            const data = response?.data.filter(i => i.category === 'ProblemType');
            const supportTeam = response?.data.filter(x => x.category === 'SupportTeam')[0].value
            currentUser = JSON.parse(localStorage.getItem("USER_INFO")).emailaddress
            setProblemTypeData(data);
            setInternalTeam(supportTeam)
            // if(internalTeam.includes(currentUser)){
            //     console.log('yes')
            // }
        } catch (error) {
            console.error(error);
        }
    };

    const handleProblemType = (selectedItem) => {
        debugger;
        setSelectedProblemTypeId(selectedItem.id)

    }
    const btnCancel = (e) => {
        navigate('/ticketList')
    }

    const errors = {};
    const formValidate = (formData) => {
        debugger;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const nameRegex = /^[A-Za-z'\s]+$/;
        const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;

        if (formData?.problemTypeId === 0) {
            errors.problemTypeId = "Required"
        }
        if (formData?.firstName?.length === 0) {
            errors.firstName = "Required"
        } else if (!nameRegex.test(formData.firstName)) {
            errors.firstName = "Should contain only alphabet";
        }


        if (formData?.lastName?.length === 0) {
            errors.lastName = "Required"
        } else if (!nameRegex.test(formData.lastName)) {
            errors.lastName = "Should contain only alphabet";
        }

        if (formData?.email?.length === 0) {
            errors.email = "Required"
        } else if (!emailRegex.test(formData.email)) {
            errors.email = "Please enter a valid email address";
        }

        if (formData?.mobileNumber?.length === 0) {
            errors.mobileNumber = "Required"
        } else if (formData.mobileNumber.length > 15) {
            errors.mobileNumber = "Enter a valid mobile number";
        }

        if (formData?.problemTitle?.length === 0) {
            errors.problemTitle = "Required"
        }
        if (formData?.problemSummary?.length === 0) {
            errors.problemSummary = "Required"
        }

        if (isEdit && formData?.resolutionSummary?.length === 0) {
            errors.resolutionSummary = "Required"
        }
        return errors;
    }

    const submitData = async (event) => {
        debugger;
        event.preventDefault();
        event.stopPropagation();
        const data = modelData;
        if (modelData.id > 0) {
            modelData.problemTypeId = 1;
        }
        else {
            modelData.problemTypeId = selectedProblemTypeId;
        }

        modelData.createdBy = JSON.parse(localStorage.getItem("USER_INFO")).userId
        if(ticketData){
            modelData.id = ticketData.id
        }
        
        setFormErrors(formValidate(modelData))
        console.log(setFormErrors)
        if (Object.keys(errors).length === 0) {
            try {
                const BASE_URL = getBaseUrl();
                const response = await axios.post(`${BASE_URL}/request/addTicket`, modelData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer " + localStorage.getItem("accessToken"),
                        },
                    }
                );
                debugger;
                console.log("response Status:", response);
                if (response?.data?.success == 1) {
                    // debugger;               
                    toast.success(<h6>{`${response?.data?.result}`}</h6>, {
                        toastId: "form-success",
                        position: "top-center",
                        autoClose: 4500,
                        onClose: () => {
                            //setIsSubmit(false);
                        },
                    });
                    navigate('/ticketList')
                } else {
                    toast.error(response?.data?.message || "Some error occurred.", {
                        toastId: "form-success",
                        onClose: () => {
                            // setIsDisabled(false);
                            // setIsSubmit(false);
                        },
                    });
                }
            } catch (exception) {
                console.error(exception);
                toast.error("Some error occurred.", {
                    toastId: "form-error",
                    onClose: () => {
                        // setIsDisabled(false);
                        // setIsSubmit(false);
                    },
                });
            }
        }
        else {
            toast.error("Please fix the form errors.", {
                toastId: "form-error",
                onClose: () => {
                    // setIsDisabled(false);
                    // setIsSubmit(false);
                },
            });
        }

    }


    return (
        <div className="__body">
            <NavBarProfile />
            <header className="Dashboard_header">
                <div className="topHeader">
                    <div className="topHeader">Raise A Ticket</div>
                </div>

            </header>
            <div className="valuation_request_Container">
                <div className="request_Form_Container">
                    <span className="title">Raise A Ticket</span>
                    <div className="basic">
                        <div className="row" style={{ "marginBottom": "20px" }}>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        First Name <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type="text" placeholder='Enter your first name' className="form-control" value={modelData.firstName} onChange={(e) => {
                                        setModelData({ ...modelData, firstName: e.target.value })
                                    }} />
                                    <label className="required required-error">
                                        {formErrors.firstName}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Last Name <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type='text' placeholder='Enter your last name' className='form-control' value={modelData.lastName}
                                        onChange={(e) => { setModelData({ ...modelData, lastName: e.target.value }) }}
                                    />
                                    <label className="required required-error">
                                        {formErrors.lastName}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Mobile Number <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type='number' placeholder='Enter your mobile number' className="form-control" value={modelData.mobileNumber} onChange={(e) =>
                                        setModelData({ ...modelData, mobileNumber: e.target.value })
                                    } />
                                    <label className="required required-error">
                                        {formErrors.mobileNumber}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ "marginBottom": "20px" }}>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Problem Type <span className="required">*</span>
                                    </label>
                                    {
                                        isEdit ?
                                            <input type="text" value={modelData.problemType} disabled className="form-control" />
                                            :
                                            <SingleSelect
                                                selectOptions={problemTypeDataList}
                                                placeholder="Select Problem Type"
                                                handleSelect={handleProblemType}
                                            />
                                    }


                                    <label className="required required-error">
                                        {formErrors.problemTypeId}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Problem Title <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type='text' placeholder='Enter Problem Title' className="form-control" value={modelData.problemTitle} onChange={(e) =>
                                        setModelData({ ...modelData, problemTitle: e.target.value })
                                    } />
                                    <label className="required required-error">
                                        {formErrors.problemTitle}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Email <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type='text' placeholder='Enter Your Email Address' className="form-control" value={modelData.email}
                                        onChange={(e) => setModelData({ ...modelData, email: e.target.value })}
                                    />
                                    <label className="required required-error">
                                        {formErrors.email}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ "marginBottom": "20px" }}>
                            <div className="col-md-12 col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Problem Summary <span className="required">*</span>
                                    </label>
                                    <input disabled={isEdit} type='text' placeholder='enter problem summary' className="ticketTextArea" value={modelData.problemSummary} onChange={(e) =>
                                        setModelData({ ...modelData, problemSummary: e.target.value })
                                    } />
                                    <label className="required required-error">
                                        {formErrors.problemSummary}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {                        
                            internalTeam.includes(currentUser) ?
                                <div className='row' style={{ "marginBottom": "20px", "display": modelData.id > 1 ? "" : "none" }} >
                                    <div className="col-md-12 col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Resolution Summary <span className="required">*</span>
                                            </label>
                                            <textarea className="ticketTextArea" style={{ "background-color": modelData.statusCode === "SRNCLSD" ? "#009d0030" : "" }} placeholder='enter resolution summary' value={modelData.resolutionSummary} onChange={(e) => setModelData({ ...modelData, resolutionSummary: e.target.value })} />

                                            <label className="required required-error">
                                                {formErrors.resolutionSummary}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                :  <div  className='row' style={{ "marginBottom": "20px", "display": modelData.id > 1 ? "" : "none",  }} >
                                    <div className="col-md-12 col-sm-6">
                                        <div className="form-group">
                                            <label>
                                                Resolution Summary <span className="required">*</span>
                                            </label>
                                            <textarea disabled={isEdit} className="ticketTextArea" style={{ "background-color": modelData.statusCode === "SRNCLSD" ? "#009d0030" : "" }} placeholder='enter resolution summary' value={modelData.resolutionSummary} onChange={(e) => setModelData({ ...modelData, resolutionSummary: e.target.value })} />

                                            <label className="required required-error">
                                                {formErrors.resolutionSummary}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                        }

                    </div>

                </div>
                <div className="request_Form_Container mt-3" style={{ display: "flex", alignItems: "end" }}>
                    <div className="justify-content-between d-flex btn-group">
                        <div className="row">
                            <div className="col-md-6">
                                <button type='button' className='btn btn-danger' onClick={() => btnCancel()}>{modelData.statusCode === "SRNCLSD" ? "BACK" : "CANCEL"}</button>

                            </div>
                            <div className="col-md-6" style={{ "display": modelData.statusCode === "SRNCLSD" ? "none" : "" }}>
                                <button className='btn btn-success' onClick={(e) => submitData(e)} >SUBMIT</button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default RaiseATicket;