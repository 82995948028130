import { BASE_URL, RequestAPI } from "./httpRequests";

export const loginUser = (data) => {
  console.log("login data:", data);

  return RequestAPI(BASE_URL + `/users/login`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const forgetPassword = (data) => {
  console.log("forgetPassword data:", data);

  return RequestAPI(BASE_URL + `/users/forgetPassword`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const resetPassword = (data) => {
  console.log("resetPassword data:", data);

  return RequestAPI(BASE_URL + `/users/resetPassword`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
