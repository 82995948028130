import {BASE_URL, HttpGet, RequestAPI} from './httpRequests'

export const addUser = (data) => {
    return RequestAPI(BASE_URL+`/users/adduser`, {
        method:"POST",
        body: JSON.stringify(data)
    })
}

export const getUser = (userId) => {
    return HttpGet(BASE_URL + `/users/me`);
}

export const updateUser = (data,userId) =>{
     return RequestAPI(BASE_URL + `/users/updateuser`, {
         method:"PUT",
         body : JSON.stringify(data)
     })
}

export const changePassword = (data) => {

    console.log(data);

    return RequestAPI(BASE_URL+`/users/changepassword`, {
        method:"POST",
        body: JSON.stringify(data)
    })
}