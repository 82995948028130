import React from "react";
import AdminDashboard from ".";

const Layout = () => {
  return (
    <div className="__body">
      <AdminDashboard />
    </div>
  );
};

export default Layout;
