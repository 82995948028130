import React from 'react'
import UserImg from '../../assets/user.jpg'

export const Profile = () => {
  const user = JSON.parse(localStorage.getItem("USER_INFO"))
  return (
    <div className='profile-header'>

    <div className="profileHeader">
    <span className="profileSpan" style={{textTransform:"uppercase"}}>Hi, {user?.userName || 'User'} </span>
    <span>  <img src={UserImg} alt="user" style={{ maxHeight: "34px", borderRadius: "4px" }} />
    </span>
  </div>
    </div>
  )
}

export default Profile;