import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { Image } from "antd";
import "react-phone-number-input/style.css";
import { Button, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { addUser } from "../../services/addUserService";
import Emmar_logo from "../../assets/hmptons-logo.png";
import bgVideo from "../../assets/hero-landscape.mp4"
import poster from "../../assets/hero-landscape-poster.jpg"
import "./signup.css";
import { toast } from "react-toastify";
import { Email } from "@mui/icons-material";

const Signup = () => {
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    address: "",
    password: "",
  };

  const [formValue, setFormValue] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const navigate = useNavigate();

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handlePhoneInput = (e) => {
    setFormValue({ ...formValue, phonenumber: e });
  };

  const Adduser = async (data) => {
    const payload = {
      firstname: data.firstname,
      lastname: data.lastname,
      emailAddress: data.email,
      password: data.password,
      mobile: data.phonenumber,
      userAddress: data.address,
    };
    const response = await addUser(payload);
    return response;
  };

  /** form submit */
  function submitForm(e) {
    console.log(formValue);
    e.preventDefault();
    const errors = validate(formValue);
    setFormErrors(errors);
    setIsSubmit(true);

    console.log(errors);

    if (Object.keys(errors).length === 0) {
      Adduser(formValue)
        .then((response) => {
          console.log("addresp:", response);
          if (response.success === 1) {
            console.log("user registered successfully");
            toast.success("registration is successfull", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              onClose: () => {
                setTimeout(() => {
                  navigate("/login");
                }, 3000);
              },
            });
          } else {
            console.log("elese respnose:", response);
            // Show error message using React Toastify
            toast.error(response?.message || response?.result?.err, {
              toastId: "login-error",
              onClose: () => {
                setIsSubmit(false);
                setIsToastOpen(false);
              },
            });
            setIsToastOpen(true);
          }
        })
        .catch((error) => {
          // Show error message using React Toastify
          toast.error("An error occurred. Please try again later.", {
            toastId: "login-error",
            onClose: () => {
              setIsSubmit(false);
              setIsToastOpen(false);
            },
          });
          setIsToastOpen(true);
        });
    } else {
      // Show error message for form validation errors
      // toast.error("Please fix the form errors.", {
      //   toastId: "form-error",
      //   onClose: () => {
      //     setIsSubmit(false);
      //     setIsToastOpen(false);
      //   },
      // });
      // setIsToastOpen(true);
    }
  }

  const validate = (values) => {
    console.log(values);

    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const nameRegex = /^[A-Za-z']+$/;
    const addressRegex = /^[a-zA-Z0-9\s\.,#\-]*$/;
    const phoneRegex = /^\+\d{1,}[0-9]{1,}$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    //nameValidations
    if (values.firstname.length === 0) {
      errors.firstname = "Required";
    } else if (!nameRegex.test(values.firstname)) {
      errors.firstname = "Should contain only alphabet";
    }

    if (values.lastname.length === 0) {
      errors.lastname = "Required";
    } else if (!nameRegex.test(values.lastname)) {
      errors.lastname = "Should contain only alphabet";
    }

    //email
    if (values.email.length === 0) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Not a valid email";
    }

    //address
    if (values.address.length === 0) {
      errors.address = "Required";
    } else if (!addressRegex.test(formValue.address)) {
      errors.address = "Should contain valid fields";
    }

    //Phone & Telephone Numbers

    if (values.phonenumber) {

      if (values.phonenumber.length === 0) {
        errors.phonenumber = "Required";
      } else if (
        values.phonenumber.length < 12 ||
        values.phonenumber.length > 16
      ) {
        errors.phonenumber = "Enter valid number";
      } else if (!phoneRegex.test(values.phonenumber)) {
        errors.phonenumber = "Should contain only numbers";
      }
    }
    else { errors.phonenumber = "Required"; }
    //pswd and confirm password
    if (values.password.length === 0) {
      errors.password = "Required";
    } else if (values.password.length < 7) {
      errors.password = "Password should be more than 6 digit";
    } else if (!passwordRegex.test(values.password)) {
      errors.password =
        "Should contain 1 digit, 1 uppercase, 1 lowercase and 1 special character !@#$%^&*";
    }

    //

    if (!values.confirmpassword) {
      errors.confirmpassword = "Required";
    } else if (values.confirmpassword.length < 6) {
      errors.confirmpassword = "more than 6";
    } else if (values.password !== values.confirmpassword) {
      errors.confirmpassword = "Did not match with Password";
    }
    return errors;
  };

  useEffect(() => {
    console.log(formErrors);

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValue);
    }
  }, [formErrors]);


  const styles = {
    fieldsContainer: {
      width: "100%",
      backgroundColor: "#1f1f218c",
      padding: "2rem",
      borderRadius: "10px",
      boxShadow: "0px 0px 12px 5px #888888",
      color: "#ffffff"
    },
    formInput: {
      /*backgroundColor:"#ffffff",
      height: "35px!important",
      padding: "5px 10px!important",
      fontSize: "12px!important",
      lineHeight: "1.5!important"*/
      backgroundColor: "#ffffff",
      border: "1px solid #ffffff"
    },

    frmLoginBtn: {
      backgroundColor: "gray"
    },
    logo: {
      width: "190px",
      height: "80px",
      opacity: "50%"
    },
    containerCenter: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
    frmAddress: {
      minHeight: "100px"
    },
    marginTop: {
      marginTop: "20px",
      marginBottom: "10px"
    },
    rowMargin: {
      marginBottom: "10px"
    }

  }

  return (
    <>
      <div className="request-body">
        <video className="bg-video" id="myVideo" autoPlay poster={poster} loop="">
          <source src={bgVideo} type="video/mp4" />
        </video>
        <div className="request-box">
          <div style={styles.fieldsContainer}>
            <div style={styles.containerCenter}>
              <Image style={styles.logo} src={Emmar_logo} />
            </div>

            <hr style={styles.divider} />


            <div className="title" style={styles.containerCenter}> Sign Up </div>
            <div className="row" style={styles.marginTop}>
              <div className="col-md-6 col-xs-1">
                <div>
                  <label className="imp">First Name 
                  <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>
                  </label>
                  <input className="form-control input-sm" formcontrolname="FullName" type="text" name="firstname" maxLength="50"
                    value={formValue.firstname} onChange={handleChange}></input>
                </div>

                <p className="req-mandatory-field">  {formErrors.firstname}</p>
              </div>

              <div className="col-md-6 col-xs-1">

                <div >
                  <label className="imp">Last Name</label>
                  <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>
                  <input required className="form-control input-sm" formcontrolname="FullName" type="text" name="lastname" maxLength="50"
                    value={formValue.lastname}
                    onChange={handleChange}></input>
                </div>

                <p className="req-mandatory-field">  {formErrors.lastname}</p>
              </div>

            </div>

            <div className="row" style={styles.rowMargin}>

              <div className="col-md-6 col-xs-1">

                <div >
                  <label className="imp">Email</label>
                  <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>

                  <input className="form-control input-sm" formcontrolname="FullName" type="email" name="email"
                    value={formValue.email}
                    onChange={handleChange}
                    maxLength="100"
                    autoComplete="off"></input>
                </div>


                <p className="req-mandatory-field"> {formErrors.email}</p>

              </div>

              <div className="col-md-6 col-xs-1">

                <div>
                  <label className="imp">Phone Number</label>
                  <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>

                  <PhoneInput
                    placeholder="Phone"
                    onChange={handlePhoneInput}
                    value={formValue.phonenumber}
                    className="form-control"
                  />
                </div>

                <p className="req-mandatory-field"> {formErrors.phonenumber}</p>
              </div>
            </div>


            <div className="row" style={styles.rowMargin}>
              <div className="col-md-6 col-xs-1">
                <label className="imp">Password</label>
                <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>

                <input className="form-control input-sm" formcontrolname="FullName" type="password" name="password"
                  value={formValue.password}
                  maxLength="100"
                  onChange={handleChange}></input>
                <p className="req-mandatory-field" style={
                  formErrors?.password?.includes('Should contain 1 digit') ? {marginBottom: 40} : {}
                  }> {formErrors.password}</p>

              </div>
              <div className="col-md-6 col-sx-1">
                <label className="imp">Confirm Password</label>
                <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>

                <input className="form-control input-sm" formcontrolname="FullName" type="password" name="confirmpassword"
                  maxLength="100" value={formValue.confirmpassword}
                  onChange={handleChange} ></input>
                <p className="req-mandatory-field"> {formErrors.confirmpassword}</p>
              </div>

            </div >


            <div className="row" style={styles.rowMargin}>

              <div className="col-md-12 col-xs-1">

                <div >
                  <label className="imp">Address</label>
                  <span className="req-mandatory-field" style={{marginLeft:5}}>*</span>

                  <textarea
                    style={styles.frmAddress}
                    className="form-control frm-textarea"
                    formcontrolname="FullName"
                    maxLength="200"
                    type="text"
                    name="address"
                    cols="100"
                    rows="4"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <p className="req-mandatory-field"> {formErrors.address}</p>
              </div>
            </div>
            <div className="bottom-btn-container">

              <div>
                <button className="btn btn-primary btn-sm" color="primary" onClick={submitForm}> SIGN UP</button>
              </div>
              <div>

                <Link to="/login" role="button" className="btn btn-sm btn-dark-gray" variant="contained"  >LOGIN</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
